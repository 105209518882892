import {ReactNode, createContext, useCallback, useContext, useMemo, useReducer} from 'react'
import Modal, {ModalProps} from 'react-bootstrap/Modal'

import TradeScopeTemplateModal, {TradeScopeTemplateModalKey} from '../components/Modals/TradeScopeTemplateModal'
import SetResourceBudget, {SetTeamMemberBudgetModalKey} from '../pages/home/projectresources/SetResourceBudget'
import {BidQuoteChecklistTradeScopeModalKey, BidQuoteChecklistTradeScopeTemplateModal} from '../pages/home/reference/BidQuoteChecklist'
import {BidBuildingConnectedProjectModal, BidBuildingConnectedProjectModalKey} from '../pages/home/setup/BidBuildingConnectedSync'
import {ClarificationsTemplatesModal, ClarificationsTemplatesModalKey} from '../pages/home/tradescope/Clarifications'
import ConfirmDateChangeModal, {ProjectDateConfirmModalKey} from '../pages/home/setup/Setup/ConfirmDateChangeModal'
import CompleteProjectSetupModal, {CompleteProjectSetupModalKey} from '../pages/home/setup/Setup/CompleteProjectSetupModal'
import EditAlternateModal, {EditAlternateModalKey} from '../../app/pages/home/Alternates/EditAlternateModal'

enum ModalAction {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE'
}

type ModalState = {
  open: boolean
  modalProps?: any
  modalKey?: string
}

type ModalReducerAction = {type: ModalAction; modalKey?: string; modalProps?: any}

interface GlobalModalContextI {
  modalState: ModalState
  closeModal: () => void
  openModal: (modalKey: string, modalProps?: any) => void
}

const GlobalModalContext = createContext<GlobalModalContextI>({
  modalState: {open: false},
  closeModal: () => console.error('GlobalModalContext not initialized'),
  openModal: (_modalKey: string, _modalProps: any) => console.error('GlobalModalContext not initialized')
})

const modalReducer = (state: ModalState, action: ModalReducerAction) => {
  switch (action.type) {
    case ModalAction.OPEN:
      return {open: true, modalProps: action.modalProps, modalKey: action.modalKey}
    case ModalAction.CLOSE:
    default:
      return {...state, open: false} // Keep previous props to avoid re-rendering if opened same modal again
  }
}

export const useAppModal = () => {
  const appModal = useContext(GlobalModalContext)
  if (!appModal) {
    throw new Error('useAppModal must be used within a GlobalModalProvider')
  }
  return appModal
}

interface GlobalModalI extends ModalProps {
  modalKey: string
  onHideCallback?: () => void
}

export const GlobalModal = ({children, modalKey, onHideCallback, ...props}: GlobalModalI) => {
  const {modalState, closeModal} = useContext(GlobalModalContext)
  const {open, modalKey: targetModalKey} = modalState

  const onHide = () => {
    if (onHideCallback) {
      onHideCallback()
    }
    closeModal()
  }

  if (targetModalKey !== modalKey) return null // Only render if modalKey matches
  return (
    <Modal show={open && modalKey === targetModalKey} onHide={onHide} {...props}>
      {children}
    </Modal>
  )
}

// Stateless Modals
const STANDALONE_MODALS = new Map<string, (props: any) => JSX.Element>([
  [TradeScopeTemplateModalKey, TradeScopeTemplateModal],
  [SetTeamMemberBudgetModalKey, SetResourceBudget],
  [BidQuoteChecklistTradeScopeModalKey, BidQuoteChecklistTradeScopeTemplateModal],
  [BidBuildingConnectedProjectModalKey, BidBuildingConnectedProjectModal],
  [ClarificationsTemplatesModalKey, ClarificationsTemplatesModal],
  [ProjectDateConfirmModalKey, ConfirmDateChangeModal],
  [CompleteProjectSetupModalKey, CompleteProjectSetupModal],
  [EditAlternateModalKey, EditAlternateModal]
])

// [EditAlternateModalKey, EditAlternateModal]

const GlobalModalProvider = ({children}: {children: ReactNode}) => {
  const [modalState, dispatch] = useReducer(modalReducer, {open: false})

  const closeModal = useCallback(() => {
    dispatch({type: ModalAction.CLOSE})
  }, [])

  const openModal = useCallback((modalKey: string, modalProps: any) => {
    dispatch({type: ModalAction.OPEN, modalKey, modalProps})
  }, [])

  const renderStandAloneModal = useCallback(() => {
    if (!modalState.modalKey) return null

    const ModalComponent = STANDALONE_MODALS.get(modalState.modalKey)
    if (!ModalComponent) return null

    const props = modalState.modalProps || {}
    return <ModalComponent {...props} />
  }, [modalState.modalKey, modalState.modalProps])

  const wrapped = useMemo(
    () => ({
      modalState,
      closeModal,
      openModal
    }),
    [modalState, closeModal, openModal]
  )

  return (
    <GlobalModalContext.Provider value={wrapped}>
      {children}
      {renderStandAloneModal()}
    </GlobalModalContext.Provider>
  )
}

export default GlobalModalProvider
