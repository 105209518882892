import usePageProps from '../../../../app/hooks/usePageProps';

function HPMHeader() {
  // const assumedUserName = useSelector(state => state.auth.assumedUserName)
    
  // function getAssumedName() {
  //   if(assumedUserName) {
  //     return (
  //       <div style={{ display: 'inline', marginLeft: 16, fontSize: 18, color: "silver" }}>
  //         ({assumedUserName})
  //       </div>
  //     )
  //   }
  // }

  const { pageHeader } = usePageProps()

  return (
      <div className='flex items-center'>
          <h1 className="text-gray-700">{pageHeader}</h1>
      </div>
  )
}

export default HPMHeader
