import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {takeLatest, put, all, select} from 'redux-saga/effects'
import axios from 'axios'
import actionTypes from './auth/actionTypes'

export const BASE_URL = process.env.REACT_APP_APIIP + '/user'

const assumedUserID = (state) => state.auth.assumedUserID
const assumedUserRole = (state) => state.auth.assumedUserRole

const initialAuthState = {
  user: undefined,
  account: undefined,
  accessToken: undefined,
  authToken: undefined,
  authTokenTimeStamp: undefined,
  userName: undefined,
  firstName: undefined,
  lastName: undefined,
  // adValid: undefined,
  roleID: undefined,
  roleDesc: undefined,
  assumedUserID: 0,
  assumedUserName: '',
  assumedUserRole: '',
  recentProjects: [],
  reauthAttempts: 0,
  Filters: {
    addResourceTeamID: 1,
    addResourceTeamName: 'Operations',
    bcprojectListTerm: '',
    bidQuoteChecklistListTerm: '',
    bidsInProgress: {constructionDate: 'current', keyword: ''},
    bidtemplates: [],
    bidTradeID: 0,
    bidTradeTemplateListTerm: '',
    bidTradeTemplateListStatus: 'All',
    bidTradeTemplateListCodeID: null,
    clarificationSection: {},
    clarificationTemplateListTerm: '',
    csicodesVersionID: '',
    csiDivisionSubgroup: 'All',
    csiDivisionID: 0,
    csiDisplayLevel: 2,
    csiLevel2CodeID: 0,
    csiCodePart2: 'All',
    csicodesTerm: '',
    csiCodeNo: '',
    csiCode: '',
    codeID: undefined,
    fullscreen: false,
    helpTerm: '',
    helpModule: 'All',
    perDiemCity: '',
    perDiemZip: '',
    perDiemFiscalYear: new Date().getFullYear().toString(),
    projectsGridStatus: '0',
    projectsGridTerm: '',
    proposalListStatus: 'All',
    searchnsynchCRM: [],
    subcontractors: [],
    subcontractorListTerm: '',
    tagsTerm: '',
    tempPerDiemDays: '',
    tempPerDaysCost: '',
    userStatus: 'Active',
    usersTerm: '',
    userRole: 'All'
  }
}

export const reducer = persistReducer(
  {
    storage,
    key: 'HPM2-auth-2023-05-19',
    whitelist: ['user', 'firstName', 'lastName', 'roleId', 'roleDesc', 'assumedUserID', 'assumedUserName', 'assumedUserRole', 'Filters', 'authToken', 'authTokenTimeStamp']
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.SET_ACCESSTOKEN: {
        process.env.REACT_APP_DEBUG && console.log('SET_ACCESSTOKEN')
        // console.log (action.payload);
        return {...state, accessToken: action.payload}
      }

      case actionTypes.SET_AUTHTOKEN: {
        process.env.REACT_APP_DEBUG && console.log('SET_AUTHTOKEN')
        // console.log (action.payload);
        return {...state, authToken: action.payload, authTokenTimeStamp: Date.now()}
      }

      case actionTypes.SET_AUTHTOKEN_ONLY: {
        process.env.REACT_APP_DEBUG && console.log('SET_AUTHTOKEN_ONLY')
        // console.log (action.payload);
        return {...state, authToken: action.payload, authTokenTimeStamp: Date.now()}
      }

      case actionTypes.SET_CLEAR_AUTHTOKEN: {
        process.env.REACT_APP_DEBUG && console.log('SET_CLEAR_AUTHTOKEN')
        // console.log (action.payload);
        return {...state, authToken: undefined}
      }

      case actionTypes.ATTEMPT_REAUTH: {
        process.env.REACT_APP_DEBUG && console.log('ATTEMPT_REAUTH')
        // console.log (action.payload);
        return {...state, reauthAttempts: state.reauthAttempts ? state.reauthAttempts + 1 : 1}
      }

      case actionTypes.RESET_REAUTH: {
        process.env.REACT_APP_DEBUG && console.log('RESET_REAUTH')
        // console.log (action.payload);
        return {...state, reauthAttempts: 0}
      }

      case actionTypes.SET_ACCOUNT: {
        process.env.REACT_APP_DEBUG && console.log('SET_ACCOUNT')
        // console.log (action.payload);
        return {...state, account: action.payload}
      }

      case actionTypes.SET_USERROLE: {
        return {
          ...state,
          userID: action.payload.userID.toString(),
          firstName: action.payload.firstname,
          lastName: action.payload.surname,
          userName: action.payload.username,
          roleDesc: action.payload.roleDesc
        }
      }

      case actionTypes.SET_ASSUMEDUSER: {
        return {
          ...state,
          // userID is not changed as this will be used and returned by the API
          assumedUserID: action.payload.assumedUserID,
          assumedUserName: action.payload.userName,
          assumedUserRole: action.payload.roleDesc
        }
      }

      case actionTypes.SET_RECENTPROJECTS: {
        return {...state, recentProjects: action.payload}
      }

      case actionTypes.AAD_ACQUIRED_ID_TOKEN_SUCCESS: {
        process.env.REACT_APP_DEBUG && console.log('AAD_ACQUIRED_ID_TOKEN_SUCCESS')
        return {...state, authToken: action.payload.idToken}
      }

      case actionTypes.AAD_LOGIN_SUCCESS: {
        process.env.REACT_APP_DEBUG && console.log('AAD_LOGIN_SUCCESS')
        return {...state, authToken: action.payload.idToken}
      }

      case 'INIT_FILTERS': {
        return {
          ...state,
          Filters: {
            ...initialAuthState.Filters,
            ...state.Filters
          }
        }
      }

      case 'SET_FILTERS': {
        return {
          ...state,
          Filters: {...state.Filters, [action.payload.filterName]: action.payload.filterValue}
        }
      }

      default:
        return state
    }
  }
)

export function* getUserRole() {
  // this process requests the Users Role which is then used to determine the appropriate menu options etc.
  console.log('getUserRole')
  try {
    let thisAssumedUserID = yield select(assumedUserID)
    let thisAssumedUserRole = yield select(assumedUserRole)
    const newURL = BASE_URL + '/userrole'
    const response = yield axios.get(newURL)
    // Check response
    if (response.status === 200) {
      const retData = response
      if (retData.returnStatus === 'Error') {
        // Report error
        // console.log('error : Warning Cannot Determine User Role  (Code - ' & response.statusText);
        yield put({type: 'SET_USERROLE', payload: {userID: 0, roleID: 0, roleDesc: 'Unknown'}})
      } else {
        yield put({type: 'SET_USERROLE', payload: response.data[0]})
        yield put({type: 'SET_VALIDUSER'})
        yield put({type: 'GET_RECENTPROJECTS'})
        // Get references that are always loaded
        yield put({type: 'FETCH_PRECON_REFERENCES'})

        // if impersonating a user then set the menu for their role
        if (thisAssumedUserID !== 0) {
          yield put({type: 'builder/SET_MENU_CONFIG', payload: thisAssumedUserRole})
        } else {
          yield put({type: 'builder/SET_MENU_CONFIG', payload: response.data[0].roleDesc})
        }
      }
    } else {
      console.log('error : Cannot Determine User Role (Code - ' & response.statusText)
      yield put({type: 'SET_USERROLE', payload: {userID: 0, roleID: 0, roleDesc: 'Unknown'}})
    }
  } catch (error) {
    console.log('error: Warning Cannot Determine User Role (Code - SVR)')
    console.log(error)
    yield put({type: 'SET_USERROLE', payload: {userID: 0, roleID: 0, roleDesc: 'Unknown'}})
  }
}

export function* setAssumedUser(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  // Set UserRole
  if (action.payload.assumedUserID === 0) {
    yield put({type: 'RESET_USERROLE'})
  } else {
    yield put({type: 'RESET_USERROLE'})
    yield put({type: 'builder/SET_MENU_CONFIG', payload: action.payload.roleDesc})
  }
}

export function* putRecentProjects(action) {
  try {
    const newURL = BASE_URL + '/recentproject'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
    } // API completed with 200, however there is an error message
    else {
      process.env.REACT_APP_DEBUG && console.log(response)
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* getRecentProjects(action) {
  try {
    const newURL = BASE_URL + '/RecentProjects'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.get(newURL)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'SET_RECENTPROJECTS', payload: response.data})
    } // API completed with 200, however there is an error message
    else {
      process.env.REACT_APP_DEBUG && console.log(response)
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export const actions = {
  setAssumedUser: (payload) => ({type: actionTypes.SET_ASSUMEDUSER, payload: payload}),
  setFilters: (payload) => ({type: actionTypes.SET_FILTERS, payload: payload}),
  putRecentProjects: (payload) => ({type: actionTypes.PUT_RECENTPROJECTS, payload: payload}),
  getRecentProjects: (payload) => ({type: actionTypes.GET_RECENTPROJECTS, payload: payload}),
  clearAuthToken: () => ({type: actionTypes.SET_CLEAR_AUTHTOKEN}),
  attemptReauth: () => ({type: actionTypes.ATTEMPT_REAUTH})
}

function* actionWatcher() {
  yield takeLatest('GET_USERROLE', getUserRole)
  yield takeLatest('SET_ASSUMEDUSER', setAssumedUser)
  yield takeLatest('RESET_USERROLE', getUserRole)
  yield takeLatest('PUT_RECENTPROJECTS', putRecentProjects)
  yield takeLatest('GET_RECENTPROJECTS', getRecentProjects)
  yield takeLatest('SET_AUTHTOKEN', getUserRole)
}

export function* saga() {
  yield all([actionWatcher()])
}
