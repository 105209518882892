import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import Dashboard from '../pages/home/Dashboard'
import ProjectSetup from '../pages/home/ProjectSetup'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {setLicenseKey} from '@grapecity/wijmo'
import {LicenseInfo} from '@mui/x-license-pro'
import '@grapecity/wijmo.styles/wijmo.css'

import '../../_metronic/assets/css/app.css'
import '../../_metronic/assets/css/app2.css'
import APIMessagesContextProvider from '../context/APIMessagesContext'
import {SubcontractorsWithTradeScope, SubcontractorsWithoutTradeScope} from '../pages/home/subcontractors/Subcontractors'
// import GridEditingContextProvider from '../context/GridEditingContext'

setLicenseKey(
  'HPM,296234316584883#B0xMUZ4l6ciV6dlJXd0FmLhRXZi5yYsF6YklmYiojIz5GRiwiI9RXaj3GblZFIlBXYjNXRiojIh94QiwiIzgDO4gTN6EzM4MjM6kjMiojIklkIs4XXbpjInxmZiwiIxY7MyAjMiojIyVmdiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TQE9UcId5N0N6LwM5RCh7b6cjM7l4K0ZHc5UjRR3WVwtEShJFOR3EamhGc5A5LwQmVlBlcnlGTN54S0ZjQ6N7Nv86T7MDM7JkQ0hnWZ3iTuFlR7cHOiVjSsNXTOFjewkkRKVTe4UkMOtyRhdHSZNHZ626ZuFmZx4ET7FGRrRGbyk6YzFlMLRUR73yRTZjQoZHTJJXVTZGajFHTyYmWwlkNWJ6SvQzK4M4KhFkdod4NhVVbSlGTTRGZBh6TEZjW626NzhmNRZVNvFmZ4QUMwlVUKR7YB9EOU3EeRNlb7hzZ8gzR624KHRGc6lGeKpmd6AlUoFkeE94YHlTSwcjb8I5V4NTeuRTREV6SGRHVZVjd0RncCFzUH3SOqRzcro4b9RHe8YFSww4NhdTOSZHNxZjNDNWWFNGVYlmbUhlNNlzMJ9UOwkmMQ5ERDNEbldnamZ4NGJ6VExmI0IyUiwiIwEkM5kDRDFjI0ICSiwCM9ITO5IDM7ETM0IicfJye35XX3JyMDBjQiojIDJCLiUmcvNEIv5mapdlI0IiTis7W0ICZyBlIsIyM4ADMxEDI6ADOwMjMwIjI0ICdyNkIsICdl9mLzVGdpNnYldXZyVneh9SZnFGdz5SZm5SbwhGL4VmbuMXZ4l6ciV6dlJXd0FmLlZWLtBHasQXZu9yclRXazJWZ7Vmc5pXYuUGc9R7b43mcw5SbwhGL4N7boxWYj3Gbs46bj9yYn5Cd4lGau4GcoxSdh9SbvNmL7RnZz9SY4VmYtMGbhNGZpJGLt36YuM6ZtQHdphmL4NXZ45SbwhGL4VmbuMXZ4l6ciV6dlJXd0FmL6VGZjxWYjVmcwxCdl9mLzVGdpNnYldXZyVneh9yYsF6YklmYsQXZu9yclRXazJWZ7Vmc5pXYucmbpdWY4NXLjxWYjVmcwxCdl9mLzVGdpNnYldXZyVneh9yZul6ZhR7ct86bpRXY0lGbpRXdmNGLt36YuM6ZtQHdphmLn9WanFGdz5SbwhGL4VmbuMXZ4l6ciV6dlJXd0FmLn9WanFGdz5yYsF6YklmYs46bj9SZjJ7bmRWdvx6YvdmLn9WanFGdz5ibvlGdhpXaslGd5xCdl9mLzVGdpNnYldXZyVneh9ibvlGdhpXaslGd5Z6Ys46bj9SZjJ7bmRWdvx6YvdmLu3Wa4FmepxWa4VHLMV3b'
)
LicenseInfo.setLicenseKey('8c4b87d4b2085991c3bcfa867ba7067aTz02NDYwMSxFPTE3MTMzMDg0MDAwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=')

const PrivateRoutes = () => {
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))

  // GCs and Fee pages
  const SummaryPage = lazy(() => import('../pages/home/Summary'))
  const PreconDept = lazy(() => import('../pages/home/PreconDept'))
  const ManpowerCombined = lazy(() => import('../pages/home/ManpowerCombined'))
  const FieldOfficeSupport = lazy(() => import('../pages/home/FieldOfficeSupport/FieldOfficeSupport'))
  const GeneralRequirements = lazy(() => import('../pages/home/GeneralRequirements/GeneralRequirements'))
  const TargetEfficiency = lazy(() => import('../pages/home/TargetEfficiency'))
  const ClientSummary = lazy(() => import('../pages/home/ClientSummary'))
  const NonBillable = lazy(() => import('../pages/home/NonBillable'))

  // Trade Pricing pages
  const SummaryPagePreCalc = lazy(() => import('../pages/home/SummaryPreCalc'))
  const BidSpreadsheet = lazy(() => import('../pages/home/BidSpreadsheet'))
  const GeneralScope = lazy(() => import('../pages/home/GeneralScope'))
  const TradeScope = lazy(() => import('../pages/home/TradeScope'))
  const BidAlternates = lazy(() => import('../pages/home/BidAlternates'))
  const BidClarifications = lazy(() => import('../pages/home/BidClarifications'))
  const BidUnitPrices = lazy(() => import('../pages/home/BidUnitPrices'))
  const BidAllowances = lazy(() => import('../pages/home/BidAllowances'))

  // Reference Pages
  const ExpenseUnits = lazy(() => import('../pages/home/reference/ExpenseUnits'))
  const States = lazy(() => import('../pages/home/reference/States'))
  const Counties = lazy(() => import('../pages/home/reference/Counties'))
  const BuildersRiskRates = lazy(() => import('../pages/home/reference/BuildersRiskRates'))
  const EmployeeRoles = lazy(() => import('../pages/home/reference/EmployeeRoles'))
  const Employees = lazy(() => import('../pages/home/reference/Employees'))
  const Tasks = lazy(() => import('../pages/home/reference/Tasks'))
  const Users = lazy(() => import('../pages/home/reference/Users'))
  const HelpTopics = lazy(() => import('../pages/home/reference/HelpTopics'))
  const HelpVideos = lazy(() => import('../pages/home/reference/HelpVideos'))
  const BusinessUnits = lazy(() => import('../pages/home/reference/BusinessUnits'))
  const SystemParams = lazy(() => import('../pages/home/reference/SystemParams'))
  const ExpenseTemplates = lazy(() => import('../pages/home/reference/ExpenseTemplates'))
  const MetaDataConfig = lazy(() => import('../pages/home/reference/MetaDataConfig'))
  const PerDiemRates = lazy(() => import('../pages/home/reference/PerDiemRates'))
  const SearchnSynchCRM = lazy(() => import('../pages/home/reference/SearchnSynchCRM'))
  const StagingDataTools = lazy(() => import('../pages/home/reference/StagingDataTools'))

  // Reference Pages
  const CSICodes = lazy(() => import('../pages/home/reference/CSICodes'))
  const BidQuoteChecklists = lazy(() => import('../pages/home/reference/BidQuoteChecklists'))
  const BidTradeTemplates = lazy(() => import('../pages/home/reference/BidTradeTemplates'))
  const ClarificationTemplates = lazy(() => import('../pages/home/reference/ClarificationTemplates'))
  const HelpVideosLibrary = lazy(() => import('../pages/home/HelpVideosLibrary'))

  return (
    <APIMessagesContextProvider>
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path='auth/*' element={<Navigate to='/dashboard' />} />

          {/* Pages */}
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='setup' element={<ProjectSetup />} />

          {/* GCs and Fee */}
          <Route
            path='summary'
            element={
              <SuspensedView>
                <SummaryPage />
              </SuspensedView>
            }
          />
          <Route
            path='precondept'
            element={
              <SuspensedView>
                <PreconDept />
              </SuspensedView>
            }
          />
          <Route
            path='projectteam'
            element={
              <SuspensedView>
                <ManpowerCombined />
              </SuspensedView>
            }
          />
          <Route
            path='fieldofficesupport'
            element={
              <SuspensedView>
                <FieldOfficeSupport />
              </SuspensedView>
            }
          />
          <Route
            path='generalrequirements'
            element={
              <SuspensedView>
                <GeneralRequirements />
              </SuspensedView>
            }
          />
          <Route
            path='targetefficiency'
            element={
              <SuspensedView>
                <TargetEfficiency />
              </SuspensedView>
            }
          />
          <Route
            path='client'
            element={
              <SuspensedView>
                <ClientSummary />
              </SuspensedView>
            }
          />
          <Route
            path='nonbillable'
            element={
              <SuspensedView>
                <NonBillable />
              </SuspensedView>
            }
          />

          {/* Trade Pricing */}
          <Route
            path='tradepricingsum'
            element={
              <SuspensedView>
                <SummaryPagePreCalc />
              </SuspensedView>
            }
          />
          <Route
            path='bidsheet'
            element={
              <SuspensedView>
                <BidSpreadsheet />
              </SuspensedView>
            }
          />
          <Route
            path='generalscope'
            element={
              <SuspensedView>
                <GeneralScope />
              </SuspensedView>
            }
          />
          <Route
            path='Trades/Subcontractors'
            element={
              <SuspensedView>
                <SubcontractorsWithoutTradeScope />
              </SuspensedView>
            }
          />
          <Route
            path='trades/addsubcontractors'
            element={
              <SuspensedView>
                <SubcontractorsWithTradeScope />
              </SuspensedView>
            }
          />
          <Route
            path='trades'
            element={
              <SuspensedView>
                <TradeScope />
              </SuspensedView>
            }
          />
          <Route
            path='bidclarifications'
            element={
              <SuspensedView>
                <BidClarifications />
              </SuspensedView>
            }
          />
          <Route
            path='alternates'
            element={
              <SuspensedView>
                <BidAlternates type='Alternate' />
              </SuspensedView>
            }
          />
          <Route
            path='ve'
            element={
              <SuspensedView>
                <BidAlternates type='VE' />
              </SuspensedView>
            }
          />
          <Route
            path='unitprices'
            element={
              <SuspensedView>
                <BidUnitPrices />
              </SuspensedView>
            }
          />
          <Route
            path='BidAllowances'
            element={
              <SuspensedView>
                <BidAllowances />
              </SuspensedView>
            }
          />
          <Route
            path='/HelpVideosLibrary'
            element={
              <SuspensedView>
                <HelpVideosLibrary />
              </SuspensedView>
            }
          />

          {/* Reference Pages */}
          <Route
            path='/Users'
            element={
              <SuspensedView>
                <Users />
              </SuspensedView>
            }
          />
          <Route
            path='/Help/HelpVideos'
            element={
              <SuspensedView>
                <HelpVideos />
              </SuspensedView>
            }
          />
          <Route
            path='/Help/HelpTopics'
            element={
              <SuspensedView>
                <HelpTopics />
              </SuspensedView>
            }
          />
          <Route
            path='/SearchnSynchCRM'
            element={
              <SuspensedView>
                <SearchnSynchCRM />
              </SuspensedView>
            }
          />

          <Route
            path='/SysTemplates/ExpenseTemplates'
            element={
              <SuspensedView>
                <ExpenseTemplates />
              </SuspensedView>
            }
          />
          <Route
            path='/SysTemplates/ExpenseUnits'
            element={
              <SuspensedView>
                <ExpenseUnits />
              </SuspensedView>
            }
          />
          <Route
            path='/SysTemplates/MetaDataConfig'
            element={
              <SuspensedView>
                <MetaDataConfig />
              </SuspensedView>
            }
          />

          <Route
            path='/BidQuoteChecklists'
            element={
              <SuspensedView>
                <BidQuoteChecklists />
              </SuspensedView>
            }
          />
          <Route
            path='/BidTradeTemplates'
            element={
              <SuspensedView>
                <BidTradeTemplates />
              </SuspensedView>
            }
          />
          <Route
            path='/Clarifications'
            element={
              <SuspensedView>
                <ClarificationTemplates />
              </SuspensedView>
            }
          />

          {/* Trades */}
          <Route
            path='/Trades/CSICodes'
            element={
              <SuspensedView>
                <CSICodes />
              </SuspensedView>
            }
          />

          {/* Employees */}
          <Route
            path='/Staff/EmployeeRoles'
            element={
              <SuspensedView>
                <EmployeeRoles />
              </SuspensedView>
            }
          />
          <Route
            path='/Staff/Employees'
            element={
              <SuspensedView>
                <Employees />
              </SuspensedView>
            }
          />

          {/* Locations */}
          <Route
            path='/Locations/States'
            element={
              <SuspensedView>
                <States />
              </SuspensedView>
            }
          />
          <Route
            path='/Locations/Counties'
            element={
              <SuspensedView>
                <Counties />
              </SuspensedView>
            }
          />
          <Route
            path='/Locations/BuildersRiskRates'
            element={
              <SuspensedView>
                <BuildersRiskRates />
              </SuspensedView>
            }
          />

          {/* Other */}
          <Route
            path='/Other/SystemParams'
            element={
              <SuspensedView>
                <SystemParams />
              </SuspensedView>
            }
          />
          <Route
            path='/Other/BusinessUnits'
            element={
              <SuspensedView>
                <BusinessUnits />
              </SuspensedView>
            }
          />
          <Route
            path='/Other/Tasks'
            element={
              <SuspensedView>
                <Tasks />
              </SuspensedView>
            }
          />
          <Route
            path='/Other/perdiemrates'
            element={
              <SuspensedView>
                <PerDiemRates />
              </SuspensedView>
            }
          />
          <Route
            path='/Other/StagingDataTools'
            element={
              <SuspensedView>
                <StagingDataTools />
              </SuspensedView>
            }
          />

          {/* Template guff */}
          <Route path='builder' element={<BuilderPageWrapper />} />

          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    </APIMessagesContextProvider>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor
    },
    barThickness: 1,
    shadowBlur: 5
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
