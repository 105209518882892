import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Autocomplete from '@mui/material/Autocomplete'
import {Formik} from 'formik'
import * as Yup from 'yup'
import {DataGridPro} from '@mui/x-data-grid-pro/DataGridPro'
import * as Precon from '../../../store/ducks/precon.duck'

export default function SubContractorContacts(props) {
  const initSubcontractorContacts = () => {
    return {
      id: 0,
      title: '',
      fullName: '',
      email: '',
      mobilePhone: '',
      phone: '',
      primaryContact: false
      // subcontractorOfficeID: 0,
    }
  }

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(Precon.actions.fetchSubcontractorContacts({subcontractorID: props.subcontractorID}))
  }, [dispatch, props.subcontractorID])
  useEffect(() => {
    dispatch(Precon.actions.fetchSubcontractorOffices({subcontractorID: props.subcontractorID}))
  }, [dispatch, props.subcontractorID])

  const offices = useSelector((state) => state.precon.SubcontractorOffices)
  const contacts = useSelector((state) => state.precon.SubcontractorContacts)
  var [contact, setContact] = useState(initSubcontractorContacts())
  var [status, setStatus] = useState('Active')
  var [office, setOffice] = useState(0)

  const handleStatusChange = (event) => {
    setStatus(event.target.value)
  }

  function loadContact(thisContact) {
    setContact(thisContact)
    setStatus(thisContact.status)
    setOffice(offices.find((office) => office.id === thisContact.subcontractorOfficeID))
    // console.log(thisContact.subcontractorOfficeID)
  }

  function newContact() {
    setContact(initSubcontractorContacts())
    setStatus('Active')
    setOffice(offices && offices[0])
  }

  const handleOfficeChange = (event, newValue) => {
    setOffice(newValue)
  }

  const contactsCols = [
    {field: 'id', headerName: 'id', hide: true},
    {
      field: 'fullName',
      type: 'string',
      headerName: 'Contact Name',
      width: 180,
      renderCell: (params) => (
        <strong>
          <div>
            <span onClick={() => loadContact(contacts.find((row) => row.id === params.row.id))} className='cell-link'>
              {params.row.fullName ? params.row.fullName : 'Not supplied'}
            </span>{' '}
            {params.row.primaryContact ? '*' : null}
          </div>
        </strong>
      )
    },
    {field: 'title', headerName: 'Title', width: 180},
    {field: 'phone', headerName: 'Phone', width: 120}
    // { field: 'email', type: 'string', headerName: 'Email', flex: 1 },
  ]

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required('Full Name is required')
  })

  const submitForm = (values) => {
    // Handle select used for head office determination
    values.status = status
    values.subContractorID = props.subcontractorID
    values.subcontractorOfficeID = office ? office.id : null
    console.log(values)
    dispatch(
      Precon.actions.putSubcontractorContacts({
        values: values,
        subcontractorID: props.subcontractorID
      })
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <h5>
          Contacts List <span className='text-[12px]'>&nbsp;&nbsp;&nbsp;&nbsp; * Primary Contact</span>
        </h5>

        <div className='bg-white-primary h-[94%]'>
          <DataGridPro
            rows={contacts}
            columns={contactsCols}
            checkboxSelection={false}
            sortModel={[{field: 'fullName', sort: 'asc'}]}
            rowHeight={38}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false
                }
              }
            }}
          />
        </div>
      </Grid>
      <Grid item md={6} className='pl-[10px]'>
        <h5>Contacts Form</h5>
        <Formik
          enableReinitialize={true}
          initialValues={contact}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            submitForm(values)
            // actions.setSubmitting(false);
          }}
        >
          {(props) => {
            const {values, errors, touched, handleChange, handleSubmit, setFieldValue} = props
            const change = (name, e) => {
              e.persist()
              handleChange(e)
            }
            return (
              <form noValidate={true} autoComplete='off' onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Position</Form.Label>
                      <Form.Control
                        type='text'
                        id='title'
                        name='title'
                        value={values.title}
                        onChange={change.bind(null, 'title')}
                        autoComplete='new-password'
                        isInvalid={touched.title && !!errors.title}
                        isValid={touched.title && !errors.title}
                      />
                      <Form.Control.Feedback type='invalid'>{errors.title}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        type='text'
                        id='fullName'
                        name='fullName'
                        value={values.fullName}
                        onChange={change.bind(null, 'fullName')}
                        autoComplete='new-password'
                        isInvalid={touched.fullName && !!errors.fullName}
                        isValid={touched.fullName && !errors.fullName}
                      />
                      <Form.Control.Feedback type='invalid'>{errors.fullName}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mt-8'>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type='text'
                        id='email'
                        name='email'
                        value={values.email}
                        onChange={change.bind(null, 'email')}
                        autoComplete='new-password'
                        isInvalid={touched.email && !!errors.email}
                        isValid={touched.email && !errors.email}
                      />
                      <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Cell Phone</Form.Label>
                      <Form.Control
                        type='text'
                        id='mobilePhone'
                        name='mobilePhone'
                        value={values.mobilePhone}
                        onChange={change.bind(null, 'mobilePhone')}
                        autoComplete='new-password'
                        isInvalid={touched.mobilePhone && !!errors.mobilePhone}
                        isValid={touched.mobilePhone && !errors.mobilePhone}
                      />
                      <Form.Control.Feedback type='invalid'>{errors.mobilePhone}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mt-8'>
                  <Col>
                    <Form.Group>
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        type='text'
                        id='phone'
                        name='phone'
                        value={values.phone}
                        onChange={change.bind(null, 'phone')}
                        autoComplete='new-password'
                        isInvalid={touched.phone && !!errors.phone}
                        isValid={touched.phone && !errors.phone}
                      />
                      <Form.Control.Feedback type='invalid'>{errors.phone}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Label>Office</Form.Label>
                    <Autocomplete
                      id='Office'
                      variant='outlined'
                      options={offices}
                      getOptionLabel={(option) => option.city + ' ' + option.stateCode}
                      value={office ? office : null}
                      onChange={(event, newValue) => handleOfficeChange(event, newValue)}
                      style={{topMargin: 0, fullWidth: true, display: 'flex', wrap: 'nowrap'}}
                      disableClearable
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          type='text'
                          variant='outlined'
                          size='small'
                          name='ContactOffice'
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                            classes: {input: 'input-text'}
                          }}
                          className='textfield mt-0 bg-white-primary'
                        />
                      )}
                    />
                  </Col>
                </Row>

                <Row className='mt-8'>
                  <Col md={12}>
                    <Form.Group>
                      <FormControl variant='outlined' size='small'>
                        <Form.Label>Status</Form.Label>
                        <Select id='statusSelector' aria-label='Contact Status' className='bg-white-primary' name='status' value={status} onChange={handleStatusChange}>
                          <MenuItem key='Active' value='Active'>
                            Active
                          </MenuItem>
                          <MenuItem key='Not Active' value='Not Active'>
                            Not Active
                          </MenuItem>
                        </Select>
                        <br />
                        <Form.Check type={'checkbox'} id={`primaryContact`} className='ml-0'>
                          <Form.Check.Input
                            type={'checkbox'}
                            className='pl-[6px]'
                            checked={values.primaryContact}
                            onChange={() => setFieldValue('primaryContact', !values.primaryContact)}
                            value={true}
                          />
                          <Form.Check.Label className='ml-2'>Primary Contact</Form.Check.Label>
                        </Form.Check>
                      </FormControl>
                    </Form.Group>
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Grid container direction='row' justifyContent='space-between'>
                    <Grid item md={6}></Grid>
                    <Grid item>
                      <Button variant='secondary' onClick={() => newContact()}>
                        Clear / New
                      </Button>
                      <Button variant='primary' type='submit' className='ml-2 mr-[10px]'>
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Row>
              </form>
            )
          }}
        </Formik>
      </Grid>
    </Grid>
  )
}
