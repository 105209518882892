/* eslint-disable no-unused-vars */
import {useState, useEffect, useMemo, useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Auth from '../../../store/ducks/auth.duck'
import * as Precon from '../../../store/ducks/precon.duck'
import * as Reference from '../../../store/ducks/reference.duck'
import {DataGridPro} from '@mui/x-data-grid-pro/DataGridPro'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {IN_PROGRESS} from './BCSyncButton'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import {useAppModal} from '../../../context/GlobalModalContext'
import Warning from '@mui/icons-material/Warning'

export default function BCProjects() {
  const dispatch = useDispatch()
  const bidQuote = useSelector((state) => state.precon.BidQuote)
  const filters = useSelector((state) => state.auth.Filters)
  const bcprojects = useSelector((state) => state.precon.BCProjects)
  const userRole = useSelector((state) => state.auth.roleDesc)
  const assumedUserRole = useSelector((state) => state.auth.assumedUserRole)
  const userID = useSelector((state) => state.auth.userID)
  const {openModal, closeModal} = useAppModal()

  useEffect(() => {
    dispatch(Precon.actions.fetchBCProjects())
  }, [dispatch])

  const [bcprojectListTerm, setBCProjectListTerm] = useState(filters.bcprojectListTerm ? filters.bcprojectListTerm : '')
  const [confirmUnlink, setConfirmUnlink] = useState('')
  const [showItem, setShowItem] = useState({id: '', hpmProjectName: '', name: '', linkedBy: '', linkedByUserID: 0})
  const [showLink, setShowLink] = useState(false)

  function changeFilter(value) {
    setBCProjectListTerm(value)
    dispatch(Auth.actions.setFilters({filterName: 'bcprojectListTerm', filterValue: value}))
  }

  const filteredbcprojects = useMemo(
    () => bcprojects.filter((bcproject) => bcproject.name.toLowerCase().search(bcprojectListTerm.toLowerCase()) > -1 || bcprojectListTerm.length === 0),
    [bcprojectListTerm, bcprojects]
  )

  const putBidQuoteBCProjectID = useCallback(
    (id) => {
      dispatch(Reference.actions.setRefStatus({statusName: 'syncBCProjectStatus', statusValue: IN_PROGRESS}))
      dispatch(Precon.actions.putBidQuoteBCProjectID({bcProjectID: id, preserveBidPackageNames: 0}))
      // dispatch(Precon.actions.setBidQuote({...bidQuote, bcProjectID: id}))
      closeModal()
    },
    [bidQuote, closeModal, dispatch]
  )

  const unlinkBidQuoteBCProjectID = (id) => {
    console.log(`unlink: ${id}}`)
    dispatch(Precon.actions.unlinkBidQuoteBCProjectID({bcProjectID: id, preserveBidPackageNames: 0, unlink: 1}))
    dispatch(Precon.actions.setBidQuote({...bidQuote, bcProjectID: 0}))
    closeModal()
  }

  const loadLink = (item) => {
    console.log('show')
    console.log(item)
    setConfirmUnlink('')
    setShowItem(item)
    setShowLink(true)
    // openModal(BuildingConnectedIModalKey)
  }

  const renderActionColumn = useCallback(
    (params) => {
      // if the BC project is linked to a bidQuote
      if (params.row.bidQuoteID > 0) {
        return (
          <strong>
            <div onClick={() => loadLink({...params.row})} className='cell-link-warning'>
              {`${params.row.bidQuoteID === bidQuote.id && (params.row.linkedByUserID === userID || (userRole === 'Administrator' && assumedUserRole === '')) ? 'Unlink' : 'In Use'}`}
            </div>
          </strong>
        )
        // else the BC project is not linked to a bidQuote
      } else {
        if (bidQuote.bcProjectID === '' || bidQuote.bcProjectID === 0) {
          return (
            <div onClick={() => putBidQuoteBCProjectID(params.row.bcProjectID)} className='cell-link'>
              Link
            </div>
          )
        } else {
          return 'Available'
        }
      }
    },
    [assumedUserRole, bidQuote.bcProjectID, bidQuote.id, putBidQuoteBCProjectID, userID, userRole]
  )

  const columns = [
    {field: 'name', type: 'string', headerName: 'Name', width: 400},
    {field: 'client', type: 'string', headerName: 'Client', width: 220},
    {field: 'bidPackagesCount', type: 'number', headerName: 'Trades', width: 90, sortable: false},
    {field: 'proposalsCount', type: 'number', headerName: 'Proposals', width: 100, sortable: false},
    {
      field: 'id',
      headerName: 'Action',
      hide: false,
      width: 100,
      renderCell: renderActionColumn
    }
  ]

  return (
    <div className='container-fluid'>
      <Paper>
        <Grid container justifyContent='space-between' className='p-[10px]'>
          <Grid item className='mt-[2px] ml-[2px]'>
            <Grid container>
              <Grid item>
                <InputGroup size='sm'>
                  <InputGroup.Text>
                    <SearchIcon fontSize='small' className='-ml-1' />
                  </InputGroup.Text>
                  <Form.Control
                    type='text'
                    value={bcprojectListTerm}
                    onChange={(event) => changeFilter(event.target.value)}
                    autoComplete='OFF'
                    className={`max-w-[170px] h-9 ${bcprojectListTerm !== '' ? 'bg-yellow-100' : 'bg-white-primary'}`}
                  />
                  <InputGroup.Text>
                    <ClearIcon onClick={() => changeFilter('')} fontSize='small' className={`-ml-[3px] ${bcprojectListTerm !== '' ? 'text-black-medium' : 'text-silver-primary'}`} />
                  </InputGroup.Text>
                </InputGroup>
              </Grid>
            </Grid>
          </Grid>

          <Grid item className='ml-5 mr-[10px]'></Grid>
        </Grid>
      </Paper>

      <Paper className='h-[700px]'>
        <DataGridPro rows={filteredbcprojects} columns={columns} checkboxSelection={false} rowHeight={38} disableColumnMenu={true} />
      </Paper>

      <Modal show={showLink} dialogClassName='edit-sm-dialog-modal-note' className='h-[700px]'>
        <Modal.Header closeButton className='edit-sm-modal-note'>
          <Modal.Title>BuildingConnected Integration</Modal.Title>
        </Modal.Header>
        <Modal.Body className='edit-sm-modal-note p-5'>
          <Grid container direction='row' justifyContent='center'>
            <Grid item md={12} className='mb-5'>
              BuildingConnected project:
              <br />
              <span className='font-semibold'>{showItem.name}</span>
            </Grid>
            <Grid item md={12}>
              Linked to HPM project:
              <br />
              <span className='font-semibold'>{showItem.hpmProjectName}</span>
            </Grid>
            <Grid item md={12}>
              {showItem.linkedBy ? (
                <>
                  ...by <span className='font-semibold'>{showItem.linkedBy}</span>
                </>
              ) : null}
            </Grid>
            {showItem.bidQuoteID === bidQuote.id && (showItem.linkedByUserID === userID || (userRole === 'Administrator' && assumedUserRole === '')) ? (
              <>
                <Grid item md={12} className='pt-[10px]'>
                  <hr></hr>
                </Grid>
                <Grid item md={12} className={'mt-3'}>
                  <Col className='p-4 bg-warning-primary rounded-md flex gap-2'>
                    <Warning fontSize='large' />
                    {/* TODO: Update warning message */}
                    <span>
                      Please check the project details above.
                      <br></br>
                      <br></br>
                      You are about to change the link to an existing project, and only the project lead should change the project link to Building Connected.
                    </span>
                  </Col>
                </Grid>
                <Grid item md={12} className={'pt-8'}>
                  To remove the project link and archive all BuildingConnected trade packages type <span className='font-semibold'>unlink</span>:
                </Grid>
                <Grid item md={4} className={'pt-4'}>
                  <Form.Control
                    type='text'
                    value={confirmUnlink}
                    onChange={(event) => setConfirmUnlink(event.target.value)}
                    autoComplete='OFF'
                    isValid={false}
                    className={`max-w-[120px] h-9 ${confirmUnlink !== '' && confirmUnlink !== 'unlink' ? 'is-invalid' : 'bg-white-primary'}`}
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
        </Modal.Body>
        <Modal.Footer className='edit-sm-modal-note'>
          <Grid container justifyContent='space-between'>
            <Grid item></Grid>
            <Grid item>
              <Button variant='secondary' onClick={() => setShowLink(false)}>
                Cancel
              </Button>
              {/* If the loaded project matches the selected BC Project bidquote, and we are an admin or the user that linked it */}
              {showItem.bidQuoteID === bidQuote.id && (showItem.linkedByUserID === userID || (userRole === 'Administrator' && assumedUserRole === '')) ? (
                <Button
                  variant='warning'
                  onClick={() => unlinkBidQuoteBCProjectID(showItem.id)}
                  className='ml-[10px]'
                  disabled={confirmUnlink === '' || (confirmUnlink !== '' && confirmUnlink !== 'unlink')}
                >
                  Unlink Project
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

const BuildingConnectedIModalKey = '@Modal_BuildingConnectedIntegration'
