import {useState, useEffect, useCallback, useMemo} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Precon from '../../../../store/ducks/precon.duck'
import * as Project from '../../../../store/ducks/project.duck'
import * as Yup from 'yup'
import {useAppModal} from '../../../../context/GlobalModalContext'
import BreakoutAreasTable from './BreakoutAreasTable'
import BreakoutAreaSummaryForm from './BreakoutAreaSummaryForm'
import EditBreakoutAreaModal, {EditBreakoutAreaModalKey} from './EditBreakoutAreaModal'

// Constants
export const UNITS_OF_QUANTITY = [
  {label: 'N/A', value: 'N/A'},
  {label: 'Units', value: 'Unit'},
  {label: 'MW', value: 'MW'},
  {label: 'Keys', value: 'Key'},
  {label: 'Spaces', value: 'Space'}
]

// Constants
export const UNITS_OF_AREA = [
  {label: 'SF', value: 'SF'},
  {label: 'RSF', value: 'RSF'},
  {label: 'GSF', value: 'GSF'},
  {label: 'USF', value: 'USF'}
]

const EditBreakoutAreaValidationSchema = Yup.object().shape({
  area: Yup.string().required('Area is required'),
  areaIndex: Yup.number().required('AreaIndex is required'),
  projectContingencyPc: Yup.number().min(0, 'Project Contingency % must not be less than 0').max(100, 'Project Contingency % must not be greater than 100'),
  designContingencyPc: Yup.number().min(0, 'Design Contingency % must not be less than 0').max(100, 'Design Contingency % must not be greater than 100'),
  escalationContingencyPc: Yup.number().min(0, 'Escalation Contingency % must not be less than 0').max(100, 'Escalation Contingency % must not be greater than 100')
})

export default function BidQuoteAreasTab() {
  const dispatch = useDispatch()
  const bidQuoteID = useSelector((state) => state.precon.bidQuoteID)
  const bidQuoteAreas = useSelector((state) => state.precon.BidQuoteAreas)
  const projectInformationGrid = useSelector((state) => state.project.ProjectInformationGrid.values)
  const {openModal, closeModal} = useAppModal()

  useEffect(() => {
    dispatch(Precon.actions.fetchBidQuoteAreas({bidQuoteID: bidQuoteID}))
  }, [dispatch, bidQuoteID])

  const initBidQuoteArea = useMemo(
    () => ({
      id: 0,
      quoteAreaId: 0,
      bidQuoteId: bidQuoteID,
      area: '',
      areaIndex: bidQuoteAreas.length + 1,
      prevIndex: null,
      isRemainderOfBaseBid: false,
      excludeSfFromProjectTotal: false,
      total: 0,
      areaSf: 0,
      areaSfLabel: 'SF',
      areaUnits: 0,
      areaUnitLabel: 'N/A',
      deleted: null,
      projectContingencyPc: 0,
      projectContingencyTotal: null,
      designContingencyPc: 0,
      designContingencyTotal: null,
      escalationContingencyPc: 0,
      escalationContingencyTotal: null
    }),
    [bidQuoteAreas.length, bidQuoteID]
  )

  const [bidQuoteArea, setBidQuoteArea] = useState(initBidQuoteArea)

  //   Controllers BreakoutAreasTable
  const loadBidQuoteArea = useCallback(
    (thisBidQuoteArea) => {
      setBidQuoteArea({...thisBidQuoteArea})
      openModal(EditBreakoutAreaModalKey)
    },
    [openModal]
  )

  //   Controllers BreakoutAreaSummaryForm
  const breakoutAreaSummaryInitialValues = useMemo(
    () => ({
      ProjectUnitValue: projectInformationGrid?.ProjectUnitValue,
      ProjectAreaValue: projectInformationGrid?.ProjectAreaValue,
      ProjectAreaUnit: projectInformationGrid?.ProjectAreaUnit,
      ProjectAreaLabel: projectInformationGrid?.ProjectAreaLabel
    }),
    [projectInformationGrid?.ProjectAreaUnit, projectInformationGrid?.ProjectAreaValue, projectInformationGrid?.ProjectUnitValue, projectInformationGrid?.ProjectAreaLabel]
  )

  const handleValidateProjectAreaUnit = useCallback(
    (value) => {
      dispatch(Project.actions.putProperty({ProjectAreaUnit: value}))
    },
    [dispatch]
  )

  const handleValidateProjectAreaLabel = useCallback(
    (value) => {
      dispatch(Project.actions.putProperty({ProjectAreaLabel: value}))
    },
    [dispatch]
  )

  const handleClickAddBreakoutArea = useCallback(() => {
    loadBidQuoteArea(initBidQuoteArea)
  }, [initBidQuoteArea, loadBidQuoteArea])

  // Controllers EditBreakoutAreaModal
  const orderOptions = useMemo(() => [{label: 1, value: 1}, ...bidQuoteAreas.map((e) => ({label: e.areaIndex + 1, value: e.areaIndex + 1}))], [bidQuoteAreas])

  const submitForm = useCallback(
    (values) => {
      var payload = {
        bidQuoteArea: values
      }
      closeModal()
      dispatch(Precon.actions.putBidQuoteArea(payload))
    },
    [closeModal, dispatch]
  )

  const deleteBidQuoteArea = useCallback(
    (values) => {
      var payload = {
        bidQuoteArea: values
      }
      closeModal()
      dispatch(Precon.actions.deleteBidQuoteArea(payload))
    },
    [closeModal, dispatch]
  )

  return (
    <div className='container-fluid'>
      <BreakoutAreaSummaryForm
        initialValues={breakoutAreaSummaryInitialValues}
        onValidateProjectAreaUnit={handleValidateProjectAreaUnit}
        onValidateProjectAreaLabel={handleValidateProjectAreaLabel}
        onClickAddBreakoutArea={handleClickAddBreakoutArea}
      />
      <BreakoutAreasTable bidQuoteAreas={bidQuoteAreas} loadBidQuoteArea={loadBidQuoteArea} />
      <EditBreakoutAreaModal
        orderOptions={orderOptions}
        initialValues={bidQuoteArea}
        validationSchema={EditBreakoutAreaValidationSchema}
        onSubmitForm={submitForm}
        onDeleteBreakoutArea={deleteBidQuoteArea}
      />
    </div>
  )
}
