import {useEffect} from 'react' //, { useMemo }
import {useDispatch, useSelector} from 'react-redux'
import * as Project from '../../store/ducks/project.duck'
import * as Auth from '../../store/ducks/auth.duck'
import BidsInProgress from '../../widgets/BidsInProgress'
import RecentActivities from '../../widgets/RecentActivities'
import {EfficiencyTrend} from '../../components/Chart/EfficiencyTrend'
import {CurrentOpportunities} from '../../components/Chart/CurrentOpportunities'
import {DashboardHighlightWidget} from '../../../_metronic/partials/widgets/statistics/DashboardHighlightWidget'
import {useIsAuthenticated} from '@azure/msal-react'

export default function Dashboard() {
  const dispatch = useDispatch()
  const assumedUserID = useSelector((state) => state.auth.assumedUserID)
  const userRole = useSelector((state) => state.auth.roleDesc)
  const userName = useSelector((state) => state.auth.userName)
  const fullscreen = useSelector((state) => state.auth.Filters.fullscreen)
  const isAuthenticated = useIsAuthenticated()
  console.log(`Dashboard: isAuthenticated: ${isAuthenticated}`)

  useEffect(() => {
    dispatch(Project.actions.fetchReportDashboardTrends({assumedUserID: assumedUserID}))
  }, [dispatch, assumedUserID])
  useEffect(() => {
    dispatch(Auth.actions.setFilters({filterName: 'fullscreen', filterValue: false}))
  }, [dispatch, fullscreen])

  if (userRole === 'Basic') {
    return (
      <div className='help-aside'>
        <p>
          No user account found for <span className='font-medium'>{userName}</span>
        </p>

        <p className='font-normal'>Please contact Enterprise Application Support for access to the HITT Pricing Model application.</p>
      </div>
    )
  } else {
    return (
      <>
        {/* begin::Row */}
        <div className='row g-4 g-xl-5 mb-0 mb-xl-0 dashboard-top'>
          <div className='col-xl-4'>
            <DashboardHighlightWidget className='card-xl-stretch mb-5 mb-xl-0' />
          </div>

          <div className='col-xl-4'>
            <CurrentOpportunities className='card-xl-stretch mb-0 mb-xl-0' />
          </div>
          <div className='col-xl-4'>
            <EfficiencyTrend className='card-xl-stretch mb-0 mb-xl-0' />
          </div>
        </div>
        {/* end::Row */}

        <div className='row g-4 g-xl-5'>
          <div className='col-xl-8'>
            <BidsInProgress />
          </div>
          <div className='col-xl-4'>
            <RecentActivities />
          </div>
        </div>
      </>
    )
  }
}
