import {put, takeLatest, takeEvery, all, select} from 'redux-saga/effects'
import axios from 'axios'
import AppToast from '../../helpers/AppToast'
import isCached from '../../components/Cache'
import actionTypes from './reference/actionTypes'

export const BASE_URL = process.env.REACT_APP_APIIP + '/reference'
export const PRECALC_BASE_URL = process.env.REACT_APP_APIIP + '/precalc' // only used for deleting video file
// console.log(BASE_URL);

const assumedUserID = (state) => state.auth.assumedUserID
const filters = (state) => state.auth.Filters
const cache = (state) => state.reference.cache
const projectID = (state) => state.project.projectID

const initialState = {
  cache: {},
  Approvers: [],
  BidQuoteTemplates: [],
  BidQuoteChecklists: [],
  BidQuoteChecklist: {
    id: 0,
    bidQuoteChecklistID: 0,
    name: '',
    description: '',
    status: '',
    istemplate: false,
    businessUnitID: '',
    updatedOn: new Date(),
    updatedBy: 0,
    items: []
  },
  BidQuoteChecklistPreview: {
    id: 0,
    bidQuoteChecklistID: 0,
    name: '',
    description: '',
    status: '',
    istemplate: false,
    businessUnitID: '',
    updatedOn: new Date(),
    updatedBy: 0,
    items: []
  },
  BidTradeTemplates: [],
  BidTradeTemplate: {
    id: 0,
    bidTradeTemplateID: 0,
    codeID: '',
    codeNo: '',
    code: '',
    templateName: '',
    status: '',
    updatedOn: new Date(),
    updatedBy: 0,
    items: []
  },
  BuildersRiskRates: [],
  BusinessUnits: [],
  Clarifications: [],
  ClarificationSections: [],
  ClarificationTemplates: [],
  ClarificationTemplate: {
    id: 0,
    clarificationTemplateID: 0,
    templateName: '',
    codeID: '',
    codeNo: '',
    code: '',
    businessUnitID: '',
    status: '',
    updatedOn: new Date(),
    updatedBy: 0,
    clarifications: []
  },
  Contexts: [],
  Counties: [],
  CSICodes: [],
  CSIDivisions: [],
  CSIDivisionSubGroups: [],
  CSIVersions: [],
  DisplayOptions: [],
  Employees: [],
  EmployeeRoles: [],
  ExpenseCategories: [],
  ExpenseItems: [],
  ExpenseUnits: [],
  ExpenseTemplates: [],
  ExpenseTemplateID: 0,
  ExpenseTemplateBidSection: '',
  ExpenseTemplateItems: [],
  help: {},
  HelpTopics: [],
  HelpVideos: [],
  Offices: [],
  PerDiemRates: [],
  Plugins: [],
  PluginOptions: {
    PricingPlugin: '',
    ContingencyBaseDirectCostOnly: 0,
    CustomFeeCalculation: 1,
    FeeExclusionsReadonly: 1
  },
  ProjectConfigurationOptions: {
    TradePricingIndirectCostInclusions: [
      // {name: 'incPreconstruction', index: 1, label: 'Preconstruction', value: true},
      // {name: 'incGeneralConditions', index: 2, label: 'General Conditions', value: true},
      // {name: 'incRetailSalesTax', index: 2, label: 'Retail Sales Tax', value: true},
      {name: 'incProjectContingency', index: 6, label: 'Project Contingency', value: false},
      {name: 'incDesignContingency', index: 7, label: 'Design Contingency', value: false},
      {name: 'incEscalationContingency', index: 8, label: 'Escalation Contingency', value: false}
      // {name: 'incPPBond', index: 3, label: 'Payment and Performance bond', value: true},
      // {name: 'incBuildersRisk', index: 5, label: 'Builders Risk', value: true},
      // {name: 'incInsurance', index: 4, label: 'Insurance', value: true}
    ],
    CalculateFeeExclusions: [
      {name: 'GeneralRequirements', index: 1, label: 'General Requirements', value: false},
      {name: 'Preconstruction', index: 2, label: 'Preconstruction', value: false},
      {name: 'GeneralConditions', index: 3, label: 'General Conditions', value: false},
      {name: 'RetailSalesTax', index: 4, label: 'Retail Sales Tax', value: false},
      {name: 'Contingency', index: 5, label: 'Contingency', value: false},
      {name: 'Bond', index: 6, label: 'P & P Bond', value: false},
      {name: 'BuildersRisk', index: 7, label: 'Builders Risk', value: false}
    ],
    ProjectContingencyFeeExclusions: [
      {name: 'Preconstruction', index: 1, label: 'Preconstruction', value: false},
      {name: 'GeneralConditions', index: 2, label: 'General Conditions', value: false},
      {name: 'RetailSalesTax', index: 5, label: 'Retail Sales Tax', value: false}
    ],
    DesignContingencyFeeExclusions: [
      {name: 'Preconstruction', index: 1, label: 'Preconstruction', value: false},
      {name: 'GeneralConditions', index: 2, label: 'General Conditions', value: false},
      {name: 'RetailSalesTax', index: 5, label: 'Retail Sales Tax', value: false}
    ],
    EscalationContingencyFeeExclusions: [
      {name: 'Preconstruction', index: 1, label: 'Preconstruction', value: false},
      {name: 'GeneralConditions', index: 2, label: 'General Conditions', value: false},
      {name: 'RetailSalesTax', index: 5, label: 'Retail Sales Tax', value: false}
    ],
    BidTotalsMiscOptions: [{name: 'ExcludeGeneralRequirementsFromDirectCosts', index: 1, label: 'Exclude General Requirements From Direct Costs', value: false}]
  },
  references: [],
  RefStatus: '',
  RefIndicator: 0,
  SearchCRM: [],
  SearchCRMStatus: '',
  States: [],
  Status: {
    syncBCProjectStatus: 'Sync with BC',
    syncChecklistToBidQuote: 'Not Started',
    resetStagingData: 'Not Started',
    fetchBidTradeGridStatus: 'Not Started',
    fetchBidQuoteChecklistStatus: 'Not Started',
    fetchBidTradeBudgetGridStatus: 'Not Started',
    isLoadedStatus: 'Refresh',
    postFileStatus: 'Not Started',
    recalcStatus: 'Recalc'
  },
  SynchCRMStatus: '',
  SyncBCProjectStatus: '',
  Tags: [],
  Tasks: [],
  UserRoles: [],
  Users: []
}

export function reducer(state = initialState, {type, ...action}) {
  switch (type) {
    case 'SET_REFERENCE': {
      return {
        ...state,
        [action.payload.referenceName]: action.payload.grid
      }
    }
    case 'SET_REFCACHE': {
      return {
        ...state,
        cache: {...state.cache, [action.payload.caller.type]: {payload: action.payload.clear ? 'VOID' : action.payload.caller.payload, resultCount: action.payload.count, timestamp: Date.now()}}
      }
    }
    case 'SET_HELP': {
      return {
        ...state,
        help: action.payload
      }
    }
    case 'SET_INITIAL': {
      return initialState
    }
    case 'SET_REFSTATUS': {
      return {
        ...state,
        Status: {...state.Status, [action.payload.statusName]: action.payload.statusValue}
      }
    }
    case 'SET_DISPLAY_OPTIONS': {
      return {...state, DisplayOptions: action.payload}
    }
    case 'SET_SEARCHCRM': {
      return {...state, SearchCRM: action.payload}
    }
    case 'SET_SEARCHCRMSTATUS': {
      return {...state, SearchCRMStatus: action.payload}
    }
    case 'SET_SYNCHCRMSTATUS': {
      return {...state, SynchCRMStatus: action.payload}
    }
    case 'SET_BidQuoteChecklistTemplateItems':
      return {
        ...state,
        BidQuoteChecklist: {
          ...state.BidQuoteChecklist,
          items: action.payload
        }
      }
    default:
      return state
  }
}

export function* putError(action) {
  const newURL = BASE_URL + '/error'
  process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
  try {
    const response = yield axios.post(newURL, action.payload)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log('ok saved error')
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
    }
  } catch (
    error // API call itself has errored out
  ) {
    console.log(error)
  }
}

export function* fetchPlugins(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/plugins/'
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        process.env.REACT_APP_DEBUG && console.log(response.data)
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'Plugins', grid: response.data}})
        yield put({type: 'SET_REFCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, undefined, `Save Failed: ${response.data.returnText}`)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchHelpTopics(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/helptopics/HPM'
      const response = yield axios.get(newURL)
      process.env.REACT_APP_DEBUG && console.log(response)
      if (response.status === 200 && response.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'HelpTopics', grid: response.data}})
        yield put({type: 'SET_REFCACHE', payload: {caller: action, count: response.data.length}})

        // For help topics, push into an object
        const helpObject = {}
        response.data.forEach(function (row) {
          helpObject[row.field.replace(/\s+/g, '')] = row.helpContent
        })
        yield put({type: 'SET_HELP', payload: helpObject})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchHelpVideos(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/helpvideos/'
      const response = yield axios.get(newURL)
      process.env.REACT_APP_DEBUG && console.log(response)
      if (response.status === 200 && response.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'HelpVideos', grid: response.data.map((item) => ({...item, modules: JSON.parse(item.modules)}))}})
        yield put({type: 'SET_REFCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* deleteHelpVideoFile(action) {
  // ok lets update the server
  try {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
    const newURL = PRECALC_BASE_URL + '/deletefile'
    const response = yield axios.post(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log('ok saved')
      yield put({type: 'FETCH_HelpVideos', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      // toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    // toast.error("Save Failed: " + action.type);
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchUsers(action) {
  const newURL = BASE_URL + '/users'
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        process.env.REACT_APP_DEBUG && console.log(response)
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'Users', grid: response.data}})
        yield put({type: 'SET_REFCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchExpenseCategories(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/expensecategories'
      process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
      const response = yield axios.get(newURL)
      process.env.REACT_APP_DEBUG && console.log(response)
      if (response.status === 200 && response.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'ExpenseCategories', grid: gridValues}})
        yield put({type: 'SET_REFCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchExpenseUnits(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/expenseunits'
      process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
      const response = yield axios.get(newURL)
      process.env.REACT_APP_DEBUG && console.log(response)
      if (response.status === 200 && response.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'ExpenseUnits', grid: gridValues}})
        yield put({type: 'SET_REFCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchEmployees(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/employees'
      process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
      const response = yield axios.get(newURL)
      process.env.REACT_APP_DEBUG && console.log(response)
      if (response.status === 200 && response.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'Employees', grid: gridValues}})
        yield put({type: 'SET_REFCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchEmployeeRoles(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/employeeRoles'
      process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
      const response = yield axios.get(newURL)
      process.env.REACT_APP_DEBUG && console.log(response)
      if (response.status === 200 && response.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'EmployeeRoles', grid: gridValues}})
        yield put({type: 'SET_REFCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchStates(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/states'
      process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
      const response = yield axios.get(newURL)
      process.env.REACT_APP_DEBUG && console.log(response)
      if (response.status === 200 && response.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'States', grid: gridValues}})
        yield put({type: 'SET_REFCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchCounties(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/counties'
      process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
      const response = yield axios.get(newURL)
      process.env.REACT_APP_DEBUG && console.log(response)
      if (response.status === 200 && response.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'Counties', grid: gridValues}})
        yield put({type: 'SET_REFCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchBusinessUnits(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/businessUnits'
      process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
      const response = yield axios.get(newURL)
      process.env.REACT_APP_DEBUG && console.log(response)
      if (response.status === 200 && response.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'BusinessUnits', grid: gridValues}})
        yield put({type: 'SET_REFCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchSystemParams(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/systemparams'
      process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
      const response = yield axios.get(newURL)
      process.env.REACT_APP_DEBUG && console.log(response)
      if (response.status === 200 && response.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'SystemParams', grid: gridValues}})
        yield put({type: 'SET_REFCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchTasks(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/tasks'
      process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
      const response = yield axios.get(newURL)
      process.env.REACT_APP_DEBUG && console.log(response)
      if (response.status === 200 && response.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'Tasks', grid: gridValues}})
        yield put({type: 'SET_REFCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchUserRoles(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/userroles'
      process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        process.env.REACT_APP_DEBUG && console.log(response)
        const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'UserRoles', grid: gridValues}})
        yield put({type: 'SET_REFCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchContexts(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/contexts'
      process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        process.env.REACT_APP_DEBUG && console.log(response)
        const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'Contexts', grid: gridValues}})
        yield put({type: 'SET_REFCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchExpenseTemplates(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/expensetemplates'
      process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        process.env.REACT_APP_DEBUG && console.log(response)
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'ExpenseTemplates', grid: response.data}})
        yield put({type: 'SET_REFCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchExpenseTemplateItems(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/expensetemplateitems/' + action.payload.id
      process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        process.env.REACT_APP_DEBUG && console.log(response)
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'ExpenseTemplateID', grid: action.payload.id}})
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'ExpenseTemplateBidSection', grid: action.payload.bidSection}})
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'ExpenseTemplateItems', grid: response.data}})
        yield put({type: 'SET_REFCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchSearchCRM(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    const newURL = BASE_URL + '/searchCRM'
    const response = yield axios.put(newURL, action.payload)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200) {
      process.env.REACT_APP_DEBUG && console.log(response.data.returnStatus)
      if (response.data.returnStatus === 'Ok') {
        yield put({type: 'SET_SEARCHCRMSTATUS', payload: response.data.searchResults.length + ' search result' + (response.data.searchResults.length === 1 ? '.' : 's.')})
        yield put({type: 'SET_SEARCHCRM', payload: response.data.searchResults})
      } else if (response.data.returnStatus === 'Error') {
        yield put({type: 'SET_SEARCHCRMSTATUS', payload: response.data.returnText})
      }
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putHelpTopic(action) {
  const newURL = BASE_URL + '/helptopic'
  process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
  try {
    const response = yield axios.put(newURL, action.payload)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_HelpTopics', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* deleteHelpTopic(action) {
  const newURL = BASE_URL + '/deletehelptopic'
  process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
  try {
    const response = yield axios.put(newURL, action.payload)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_HelpTopics', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putHelpVideo(action) {
  const newURL = BASE_URL + '/helpvideo'
  process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
  try {
    const response = yield axios.put(newURL, action.payload)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_HelpVideos', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putTaskGridRow(action) {
  const newURL = BASE_URL + '/taskgridrow'
  process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
  try {
    const response = yield axios.put(newURL, action.payload.item)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_Tasks', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putEmployeeGridRow(action) {
  const newURL = BASE_URL + '/employeegridrow'
  process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
  try {
    const response = yield axios.put(newURL, action.payload.item)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_Employees', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putEmployeeRoleGridRow(action) {
  const newURL = BASE_URL + '/employeerolegridrow'
  process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
  try {
    const response = yield axios.put(newURL, action.payload.item)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_Employees', payload: {popCache: true}})
      yield put({type: 'FETCH_EmployeeRoles', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putStateGridRow(action) {
  const newURL = BASE_URL + '/stategridrow'
  process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
  try {
    const response = yield axios.put(newURL, action.payload.item)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_States', payload: {popCache: true}})
      yield put({type: 'CALC_PROJECT'})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putCountyGridRow(action) {
  const newURL = BASE_URL + '/countygridrow'
  process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
  try {
    const response = yield axios.put(newURL, action.payload.item)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_Counties', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putBuildersRiskRateGridRow(action) {
  const newURL = BASE_URL + '/buildersriskratesgridrow'
  process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
  try {
    const response = yield axios.put(newURL, action.payload)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_BuildersRiskRates', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putOfficeGridRow(action) {
  const newURL = BASE_URL + '/officegridrow'
  process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
  try {
    const response = yield axios.put(newURL, action.payload.item)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_Offices', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putExpenseCategoryGridRow(action) {
  const newURL = BASE_URL + '/expensecategorygridrow'
  process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
  try {
    const response = yield axios.put(newURL, action.payload)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_EXPENSECATEGORIES', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putDeleteExpenseCategoryGridRow(action) {
  const newURL = BASE_URL + '/deleteexpensecategorygridrow'
  process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
  try {
    const response = yield axios.put(newURL, action.payload)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_EXPENSECATEGORIES', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putExpenseItemGridRow(action) {
  const newURL = BASE_URL + '/expensetemplateitem'
  process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
  try {
    const response = yield axios.put(newURL, action.payload)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_EXPENSETEMPLATEITEMS', payload: {id: action.payload.expenseTemplateID, bidSection: action.payload.bidSection, popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putBusinessUnitRow(action) {
  const newURL = BASE_URL + '/businessunitrow'
  process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
  try {
    const response = yield axios.put(newURL, action.payload.item)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_BusinessUnits', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putExpenseUnitRow(action) {
  const newURL = BASE_URL + '/expenseunit'
  process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
  try {
    const response = yield axios.put(newURL, action.payload.item)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_ExpenseUnits', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putDeleteExpenseUnitRow(action) {
  const newURL = BASE_URL + '/deleteexpenseunit'
  process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
  try {
    const response = yield axios.put(newURL, action.payload)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_ExpenseUnits', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putDeleteExpenseTemplate(action) {
  const newURL = BASE_URL + '/deleteexpensetemplate'
  process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
  try {
    const response = yield axios.put(newURL, action.payload)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_EXPENSETEMPLATES', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putUser(action) {
  const newURL = BASE_URL + '/biduser'
  process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
  try {
    // const jPayload =  JSON.stringify(action.payload)
    const response = yield axios.put(newURL, action.payload)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_Users', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* deleteUser(action) {
  const newURL = BASE_URL + '/deleteuser'
  process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
  try {
    const jPayload = JSON.stringify(action.payload)
    const response = yield axios.put(newURL, {jPayload})
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_Users', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putSystemParameter(action) {
  const newURL = BASE_URL + '/systemparameter'
  process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
  try {
    const response = yield axios.put(newURL, action.payload)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_SystemParams', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putNewBidPermission(action) {
  const newURL = BASE_URL + '/userbidpermission/' + action.payload.newPermissionUserID + '/' + action.payload.newPermissionBidID + '/' + action.payload.newPermissionType
  process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
  try {
    const response = yield axios.put(newURL)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      yield put({type: 'FETCH_Users', payload: {popCache: true}})
      yield put({type: 'FETCH_BidPermissions', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putDeleteBidPermission(action) {
  const newURL = BASE_URL + '/deleteuserbidpermission/' + action.payload.permissionUserID + '/' + action.payload.permissionBidID
  process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action))
  try {
    const response = yield axios.put(newURL)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok deleted: ${action.type}`)
      yield put({type: 'FETCH_Users', payload: {popCache: true}})
      yield put({type: 'FETCH_BidPermissions', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putExpenseTemplate(action) {
  try {
    const newURL = BASE_URL + '/expensetemplate'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'FETCH_EXPENSETEMPLATES', payload: {tagValues: action.payload.tagValuesFilter, popCache: true}})
      yield put({type: 'FETCH_EXPENSECATEGORIES', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putDeleteExpenseTemplateItem(action) {
  try {
    const newURL = BASE_URL + '/deleteexpensetemplateitem'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'FETCH_EXPENSETEMPLATEITEMS', payload: {id: action.payload.expenseTemplateID, bidSection: action.payload.bidSection, popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* getDisplayOptions(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + `/userdisplayoptions/${action.payload.projectID > 0 ? action.payload.projectID : 0}`
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        process.env.REACT_APP_DEBUG && console.log(response)
        yield put({type: 'SET_DISPLAY_OPTIONS', payload: response.data})
        yield put({type: 'SET_REFCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* putDisplayOptions(action) {
  try {
    const newURL = BASE_URL + `/userdisplayoption/${action.payload.projectID}/`
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response)
      yield put({type: 'FETCH_DISPLAY_OPTIONS', payload: {projectID: action.payload.projectID, popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchBuildersRiskRates(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    // if not passed a year, then default to current year
    let payload = action.payload
    if (!payload) {
      payload = new Date().getFullYear()
    }
    try {
      const newURL = BASE_URL + '/buildersriskrates/' + 2024
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        process.env.REACT_APP_DEBUG && console.log(response.data)
        const gridValues = JSON.parse(response.data[0].result).map((item) => ({...item}))
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'BuildersRiskRates', grid: gridValues}})
        yield put({type: 'SET_REFCACHE', payload: {caller: action, count: gridValues.length}})
      } // API completed with 200, however there is an error message
      else {
        process.env.REACT_APP_DEBUG && console.log(response)
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchTags(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/tags/'
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        process.env.REACT_APP_DEBUG && console.log(response.data)
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'Tags', grid: response.data}})
        yield put({type: 'SET_REFCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        process.env.REACT_APP_DEBUG && console.log(response)
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* putTag(action) {
  try {
    const newURL = BASE_URL + '/tag/'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'FETCH_Tags', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* postSearchCRM(action) {
  try {
    const newURL = BASE_URL + '/searchCRM'
    console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.post(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'SET_SYNCHCRMSTATUS', payload: response.data.returnText})
      // toast.success(`Sync Process Requested`);
    } // API completed with 200, however there is an error message
    else {
      yield put({type: 'SET_SYNCHCRMSTATUS', payload: response.data.returnText})
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchPerDiemRates(action) {
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
  try {
    var newPayload = {...action.payload}
    if (!action.payload.state || (action.payload.state && action.payload.state === '')) {
      newPayload.state = 'All'
    }
    if (!action.payload.city || (action.payload.city && action.payload.city === '')) {
      newPayload.city = 'All'
    }
    if (!action.payload.zip || (action.payload.zip && action.payload.zip === 0)) {
      newPayload.zip = 0
    }
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(newPayload))
    const newURL = BASE_URL + '/perDiemRates'
    const response = yield axios.put(newURL, newPayload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'SET_REFERENCE', payload: {referenceName: 'PerDiemRates', grid: response.data}})
    } // API completed with 200, however there is an error message
    else {
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putPerDiemRate(action) {
  try {
    const newURL = BASE_URL + '/perDiemRate/'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      let currentFilters = yield select(filters)
      yield put({type: 'FETCH_PERDIEMRATES', payload: {city: currentFilters.perDiemCity, zip: currentFilters.perDiemZip, fiscalYear: currentFilters.perDiemFiscalYear, popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* deletePerDiemRate(action) {
  try {
    const newURL = BASE_URL + '/deleteperdiemrate/'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'FETCH_PERDIEMRATES', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* postSyncBCProject(action) {
  try {
    const newURL = BASE_URL + '/syncbcproject'
    console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.post(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'SET_REFSTATUS', payload: {statusName: 'syncBCProjectStatus', statusValue: response.data.returnText}})
      // toast.success(`Sync Process Requested`);
    } // API completed with 200, however there is an error message
    else {
      yield put({type: 'SET_REFSTATUS', payload: {statusName: 'syncBCProjectStatus', statusValue: response.data.returnText}})
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchCSIDivisions(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/csiDivisions/'
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        process.env.REACT_APP_DEBUG && console.log(response.data)
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'CSIDivisions', grid: response.data}})
        yield put({type: 'SET_REFCACHE', payload: {caller: action, count: response.data.length}})
        // extract sub groups from divisions
        const subGroups = [...new Set(response.data.map((item) => item.subGroup))] // [ 'A', 'B']
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'CSIDivisionSubGroups', grid: subGroups}})
      } // API completed with 200, however there is an error message
      else {
        process.env.REACT_APP_DEBUG && console.log(response)
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchCSICodes(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      var mode = action.payload.mode ? action.payload.mode : 'Select'
      var buFilter = action.payload.buFilter ? action.payload.buFilter : 'All'
      var bidQuoteID = action.payload.bidQuoteID ? action.payload.bidQuoteID : ''
      const newURL = BASE_URL + '/csicodes' + '/' + mode + '/' + buFilter + '/' + bidQuoteID
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        // process.env.REACT_APP_DEBUG && console.log(response.data);
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'CSICodes', grid: response.data}})
        yield put({type: 'SET_REFCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        process.env.REACT_APP_DEBUG && console.log(response)
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* putCSICode(action) {
  try {
    const newURL = BASE_URL + '/csicode'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'FETCH_CSICodes', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(undefined, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putBusinessUnitCsiCode(action) {
  try {
    const newURL = BASE_URL + '/putBusinessUnitCsiCode'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'FETCH_CSICodes', payload: {mode: action.payload.mode, buFilter: action.payload.buFilter, popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(undefined, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchBidQuoteChecklistTemplates(action) {
  let currentAssumedUserID = yield select(assumedUserID)
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/bidquotechecklisttemplates/' + currentAssumedUserID
      const response = yield axios.get(newURL)
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        process.env.REACT_APP_DEBUG && console.log(response.data)
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'BidQuoteChecklists', grid: response.data}})
        yield put({type: 'SET_REFCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        process.env.REACT_APP_DEBUG && console.log(response)
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchBidQuoteChecklistTemplate(action) {
  try {
    if (action.payload && action.payload.bidQuoteChecklistID === 0) {
      yield put({type: 'SET_REFERENCE', payload: {referenceName: action.payload.preview ? 'BidQuoteChecklistPreview' : 'BidQuoteChecklist', grid: initialState.BidQuoteChecklist}})
      yield put({type: 'SET_REFCACHE', payload: {caller: action, count: 1}})
    } else {
      const newURL = BASE_URL + '/bidquotechecklisttemplate/' + action.payload.bidQuoteChecklistID
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        process.env.REACT_APP_DEBUG && console.log(response.data)
        yield put({type: 'SET_REFERENCE', payload: {referenceName: action.payload.preview ? 'BidQuoteChecklistPreview' : 'BidQuoteChecklist', grid: response.data[0]}})
        yield put({type: 'SET_REFSTATUS', payload: {statusName: 'fetchBidQuoteChecklistStatus', statusValue: 'Complete'}})
        yield put({type: 'SET_PRECON_UPDATES', payload: {name: 'BidQuoteChecklist', id: action.payload && action.payload.bidQuoteChecklistID ? action.payload.bidQuoteChecklistID : 0, value: 0}})
        yield put({type: 'SET_REFCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        process.env.REACT_APP_DEBUG && console.log(response)
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
        })
      }
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putBidQuoteChecklistTemplate(action) {
  let currentAssumedUserID = yield select(assumedUserID)
  try {
    const newURL = BASE_URL + '/bidquotechecklisttemplate'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, {...action.payload, assumedUserID: currentAssumedUserID})
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'FETCH_BidQuoteChecklistTemplates', payload: {popCache: true}})
      // yield put({type: 'FETCH_BidQuoteChecklistTemplate', payload: {bidQuoteChecklistID: action.payload.bidQuoteChecklist.bidQuoteChecklistID, popCache: true}})
      // If adding a new checklist, set a temp id var in state so that the form is refreshed with the new ID for grid inserts
      if (action.payload.bidQuoteChecklist.id === 0) {
        yield put({type: 'SET_PRECON_TEMP', payload: {name: 'InsertedBidQuoteChecklistID', value: response.data.newID}})
      }
    } // API completed with 200, however there is an error message
    else {
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* deleteBidQuoteChecklistTemplate(action) {
  try {
    const newURL = BASE_URL + '/deletebidquotechecklist'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'FETCH_BidQuoteChecklistTemplates', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putBidQuoteChecklistItemTemplate(action) {
  try {
    const newURL = BASE_URL + '/bidquotechecklistitem'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload.gridRows)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
    } // API completed with 200, however there is an error message
    else {
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* deleteBidQuoteChecklistItemTemplate(action) {
  try {
    const newURL = BASE_URL + '/deletebidquotechecklistitem'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload.gridRows)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'FETCH_BidQuoteChecklistTemplate', payload: {bidQuoteChecklistID: action.payload.bidQuoteChecklistID, popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchBidTradeTemplates(action) {
  try {
    const newURL = BASE_URL + '/bidTradeTemplates/'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.get(newURL)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'SET_REFERENCE', payload: {referenceName: 'BidTradeTemplates', grid: response.data}})
    } // API completed with 200, however there is an error message
    else {
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchBidTradeTemplate(action) {
  try {
    if (action.payload === 0) {
      yield put({type: 'SET_REFERENCE', payload: {referenceName: 'BidTradeTemplate', grid: initialState.BidTradeTemplate}})
    } else {
      const newURL = BASE_URL + '/bidTradeTemplate/' + action.payload
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        process.env.REACT_APP_DEBUG && console.log(response.data)
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'BidTradeTemplate', grid: response.data[0]}})
      } // API completed with 200, however there is an error message
      else {
        process.env.REACT_APP_DEBUG && console.log(response)
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
        })
      }
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putBidTradeTemplate(action) {
  try {
    const newURL = BASE_URL + '/bidTradeTemplate/'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'FETCH_BIDTRADETEMPLATES', payload: {popCache: true}})
      if (action.payload.bidTradeTemplate.id === 0) {
        // yield put({ type: 'FETCH_BIDTRADETEMPLATE', payload: response.data.newID });
        yield put({type: 'SET_PRECON_TEMP', payload: {name: 'InsertedBidTradeTemplateID', value: response.data.newID}})
      }
    } // API completed with 200, however there is an error message
    else {
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* deleteBidTradeTemplate(action) {
  try {
    const newURL = BASE_URL + '/deletebidtradetemplate/'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'FETCH_BIDTRADETEMPLATES', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putBidTradeItemTemplate(action) {
  try {
    const newURL = BASE_URL + '/bidTradeItemTemplate/'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      // yield put({type: 'FETCH_BIDTRADETEMPLATE', payload: action.payload.bidTradeItemTemplate.bidTradeTemplateID})
    } // API completed with 200, however there is an error message
    else {
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* deleteBidTradeItemTemplate(action) {
  try {
    const newURL = BASE_URL + '/deletebidtradeitemtemplate/'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      // yield put({type: 'FETCH_BIDTRADETEMPLATE', payload: action.payload.bidTradeItemTemplate.bidTradeTemplateID})
    } // API completed with 200, however there is an error message
    else {
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchApprovers(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      let currentProjectID = yield select(projectID)
      const newURL = BASE_URL + '/approvers/' + currentProjectID
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        process.env.REACT_APP_DEBUG && console.log(response.data)
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'Approvers', grid: response.data}})
        yield put({type: 'SET_REFCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        process.env.REACT_APP_DEBUG && console.log(response)
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchClarificationTemplates(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/clarificationTemplates/'
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        process.env.REACT_APP_DEBUG && console.log(response.data)
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'ClarificationTemplates', grid: response.data}})
        yield put({type: 'SET_REFCACHE', payload: {caller: action, count: response.data.length}})
      } // API completed with 200, however there is an error message
      else {
        process.env.REACT_APP_DEBUG && console.log(response)
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
        })
      }
    } catch (
      error // API call itself has errored out
    ) {
      const appToast = new AppToast(error, action.type)
      appToast.displayErrorToast()
      yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
    }
  }
}

export function* fetchClarificationTemplate(action) {
  try {
    if (action.payload === 0) {
      yield put({type: 'SET_REFERENCE', payload: {referenceName: 'ClarificationTemplate', grid: initialState.ClarificationTemplate}})
    } else {
      const newURL = BASE_URL + '/clarificationtemplate/' + action.payload
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
      const response = yield axios.get(newURL)
      if (response.status === 200 && response.data.returnStatus !== 'Error') {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
        process.env.REACT_APP_DEBUG && console.log(response.data)
        yield put({type: 'SET_REFERENCE', payload: {referenceName: 'ClarificationTemplate', grid: response.data[0]}})
      } // API completed with 200, however there is an error message
      else {
        process.env.REACT_APP_DEBUG && console.log(response)
        const appToast = new AppToast(undefined, action.type)
        appToast.displayErrorToast()
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
        })
      }
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putClarificationTemplate(action) {
  let currentAssumedUserID = yield select(assumedUserID)
  try {
    const newURL = BASE_URL + '/clarificationTemplate/'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, {...action.payload, assumedUserID: currentAssumedUserID})
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'FETCH_CLARIFICATIONTEMPLATES', payload: {popCache: true}})
      if (action.payload.clarificationTemplate.id === 0) {
        yield put({type: 'SET_PRECON_TEMP', payload: {name: 'InsertedClarificationTemplateID', value: response.data.newID}})
      }
    } // API completed with 200, however there is an error message
    else {
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* deleteClarificationTemplate(action) {
  try {
    const newURL = BASE_URL + '/deleteclarificationtemplate/'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      yield put({type: 'FETCH_CLARIFICATIONTEMPLATES', payload: {popCache: true}})
    } // API completed with 200, however there is an error message
    else {
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putClarification(action) {
  try {
    const newURL = BASE_URL + '/clarification'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      // yield put({type: 'FETCH_CLARIFICATIONTEMPLATE', payload: action.payload.clarification.clarificationTemplateID})
    } // API completed with 200, however there is an error message
    else {
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* deleteClarification(action) {
  try {
    const newURL = BASE_URL + '/deleteclarification'
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`)
    const response = yield axios.put(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      // yield put({type: 'FETCH_CLARIFICATIONTEMPLATE', payload: action.payload.clarification.clarificationTemplateID})
    } // API completed with 200, however there is an error message
    else {
      process.env.REACT_APP_DEBUG && console.log(response)
      const appToast = new AppToast(undefined, action.type)
      appToast.displayErrorToast()
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (
    error // API call itself has errored out
  ) {
    const appToast = new AppToast(error, action.type)
    appToast.displayErrorToast()
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchReferences() {
  // Put all the properties references
  yield put({type: 'INIT_FILTERS'})
  yield put({type: 'FETCH_SystemParams'})
  yield put({type: 'FETCH_PLUGINS'})
  yield put({type: 'FETCH_HelpTopics'})
  yield put({type: 'FETCH_HelpVideos'})

  // yield put({ type: 'FETCH_Contexts' })
  // yield put({ type: 'FETCH_Tags' })
  // yield put({ type: 'FETCH_BusinessUnits' })
  // yield put({ type: 'FETCH_EXPENSECATEGORIES' })
  // yield put({ type: 'FETCH_ExpenseUnits' })
  // yield put({ type: 'FETCH_Employees' })
  // yield put({ type: 'FETCH_EmployeeRoles' })
  // yield put({ type: 'FETCH_Offices' })
  // yield put({ type: 'FETCH_States' })
  // yield put({ type: 'FETCH_Counties' })
  // yield put({ type: 'FETCH_BuildersRiskRates' })
  // yield put({ type: 'FETCH_Tasks' })
  // yield put({ type: 'FETCH_UserRoles' })
  // yield put({ type: 'FETCH_Users' })
  // yield put({ type: 'FETCH_HelpTopics' })

  // // PreCalc
  // yield put({ type: 'FETCH_CSIVersions' })
  // yield put({ type: 'FETCH_CSIDIVISIONS' })
  // yield put({ type: 'FETCH_CSICodes' })
  // yield put({ type: 'FETCH_BidQuoteChecklists' })
  // yield put({ type: 'FETCH_APPROVERS' })
  // yield put({ type: 'FETCH_DISPLAY_OPTIONS' });
}

export const actions = {
  setCache: (payload) => ({type: actionTypes.SET_REFCACHE, payload: payload}),
  clearCache: (payload) => ({type: actionTypes.SET_REFCACHE, payload: {caller: {type: payload?.type}, clear: true}}),
  setReferenceInitialState: () => ({type: actionTypes.SET_INITIAL}),
  // fetchReference:                              () => ({ type: actionTypes.FETCH_REFERENCES }),
  getDisplayOptions: (payload) => ({type: actionTypes.FETCH_DISPLAY_OPTIONS, payload: payload}),
  putDisplayOptions: (payload) => ({type: actionTypes.PUT_DISPLAY_OPTIONS, payload: payload}),
  fetchPlugins: (payload) => ({type: actionTypes.FETCH_PLUGINS, payload: payload}),

  fetchStates: (payload) => ({type: actionTypes.FETCH_States, payload: payload}),
  fetchCounties: (payload) => ({type: actionTypes.FETCH_Counties, payload: payload}),
  fetchBusinessUnits: (payload) => ({type: actionTypes.FETCH_BusinessUnits, payload: payload}),
  fetchEmployees: (payload) => ({type: actionTypes.FETCH_Employees, payload: payload}),
  fetchExpenseUnits: (payload) => ({type: actionTypes.FETCH_ExpenseUnits, payload: payload}),
  fetchTasks: (payload) => ({type: actionTypes.FETCH_Tasks, payload: payload}),

  fetchUsers: (payload) => ({type: actionTypes.FETCH_Users, payload: payload}),
  fetchApprovers: (payload) => ({type: actionTypes.FETCH_APPROVERS, payload: payload}),
  fetchCSIDivisions: (payload) => ({type: actionTypes.FETCH_CSIDIVISIONS, payload: payload}),
  fetchCSICodes: (payload) => ({type: actionTypes.FETCH_CSICodes, payload: payload}),

  putCSICode: (payload) => ({type: actionTypes.PUT_CSICODE, payload: payload}),
  putBusinessUnitCsiCode: (payload) => ({type: actionTypes.PUT_BUSINESSUNIT_CSICODE, payload: payload}),
  deleteClarification: (payload) => ({type: actionTypes.DELETE_Clarification, payload: payload}),

  fetchContexts: (payload) => ({type: actionTypes.FETCH_Contexts, payload: payload}),
  fetchTags: (payload) => ({type: actionTypes.FETCH_Tags, payload: payload}),
  fetchExpenseTemplates: (payload) => ({type: actionTypes.FETCH_EXPENSETEMPLATES, payload: payload}),
  fetchExpenseCategories: (payload) => ({type: actionTypes.FETCH_EXPENSECATEGORIES, payload: payload}),
  fetchHelpTopics: (payload) => ({type: actionTypes.FETCH_HelpTopics, payload: payload}),
  fetchHelpVideos: (payload) => ({type: actionTypes.FETCH_HelpVideos, payload: payload}),
  deleteHelpVideoFile: (payload) => ({type: actionTypes.DELETE_HelpVideoFile, payload: payload}),
  fetchPerDiemRates: (payload) => ({type: actionTypes.FETCH_PERDIEMRATES, payload: payload}),
  fetchExpenseTemplateItems: (payload) => ({type: actionTypes.FETCH_EXPENSETEMPLATEITEMS, payload: payload}),
  fetchBuildersRiskRates: (payload) => ({type: actionTypes.FETCH_BuildersRiskRates, payload: payload}),
  fetchEmployeeRoles: (payload) => ({type: actionTypes.FETCH_EmployeeRoles, payload: payload}),

  fetchSearchCRM: (payload) => ({type: actionTypes.FETCH_SEARCHCRM, payload: payload}),
  setSearchCRMStatus: (payload) => ({type: actionTypes.SET_SEARCHCRMSTATUS, payload: payload}),

  setRefStatus: (payload) => ({type: actionTypes.SET_REFSTATUS, payload: payload}),
  setSynchCRMStatus: (payload) => ({type: actionTypes.SET_SYNCHCRMSTATUS, payload: payload}),

  changeExpenseCategoryGridRow: (payload) => ({type: actionTypes.CHANGE_EXPENSE_CATEGORY_GRID_ROW, payload: payload}),
  deleteExpenseCategoryGridRow: (payload) => ({type: actionTypes.DELETE_EXPENSE_CATEGORY_GRID_ROW, payload: payload}),
  changeExpenseItemGridRow: (payload) => ({type: actionTypes.CHANGE_EXPENSE_ITEM_GRID_ROW, payload: payload}),
  changeExpenseUnitRow: (payload) => ({type: actionTypes.CHANGE_EXPENSE_UNIT_ROW, payload: payload}),
  changeOfficeGridRow: (payload) => ({type: actionTypes.CHANGE_OFFICE_GRID_ROW, payload: payload}),
  changeStateGridRow: (payload) => ({type: actionTypes.CHANGE_STATE_GRID_ROW, payload: payload}),
  changeCountyGridRow: (payload) => ({type: actionTypes.CHANGE_COUNTY_GRID_ROW, payload: payload}),
  changeBRRateGridRow: (payload) => ({type: actionTypes.CHANGE_BUILDERSRISKRATE_GRID_ROW, payload: payload}),
  changeEmployeeRoleGridRow: (payload) => ({type: actionTypes.CHANGE_EMPLOYEE_ROLE_GRID_ROW, payload: payload}),
  changeEmployeeGridRow: (payload) => ({type: actionTypes.CHANGE_EMPLOYEE_GRID_ROW, payload: payload}),
  changeTaskGridRow: (payload) => ({type: actionTypes.CHANGE_TASK_GRID_ROW, payload: payload}),
  changeUserRoleGridRow: (payload) => ({type: actionTypes.CHANGE_USER_ROLE_GRID_ROW, payload: payload}),

  putHelpTopic: (payload) => ({type: actionTypes.PUT_HELP_TOPIC, payload: payload}),
  putHelpVideo: (payload) => ({type: actionTypes.PUT_HELP_VIDEO, payload: payload}),
  changeBusinessUnitRow: (payload) => ({type: actionTypes.CHANGE_BUSINESSUNIT_ROW, payload: payload}),
  postSearchCRM: (payload) => ({type: actionTypes.PUT_SEARCHCRM, payload: payload}),
  postSyncBCProject: (payload) => ({type: actionTypes.PUT_SYNCBCPROJECT, payload: payload}),
  deleteHelpTopic: (payload) => ({type: actionTypes.DELETE_HelpTopic, payload: payload}),
  putPerDiemRate: (payload) => ({type: actionTypes.PUT_PERDIEMRATE, payload: payload}),
  deletePerDiemRate: (payload) => ({type: actionTypes.DELETE_PERDIEMRATE, payload: payload}),

  addBidPermission: (payload) => ({type: actionTypes.ADD_BID_PERMISSION, payload: payload}),
  deleteBidPermission: (payload) => ({type: actionTypes.DELETE_BID_PERMISSION, payload: payload}),
  deleteExpenseTemplate: (payload) => ({type: actionTypes.DELETE_EXPENSETEMPLATE, payload: payload}),
  deleteExpenseUnitRow: (payload) => ({type: actionTypes.DELETE_EXPENSE_UNIT_ROW, payload: payload}),
  deleteExpenseTemplateItem: (payload) => ({type: actionTypes.DELETE_EXPENSETEMPLATEITEM, payload: payload}),

  putUser: (payload) => ({type: actionTypes.PUT_USER, payload: payload}),
  deleteUser: (payload) => ({type: actionTypes.DELETE_USER, payload: payload}),
  putSystemParameter: (payload) => ({type: actionTypes.PUT_SYSTEMPARAMETER, payload: payload}),
  putTag: (payload) => ({type: actionTypes.PUT_TAG, payload: payload}),
  putExpenseTemplate: (payload) => ({type: actionTypes.PUT_EXPENSETEMPLATE, payload: payload}),

  fetchBidTradeTemplates: () => ({type: actionTypes.FETCH_BIDTRADETEMPLATES}),
  fetchBidTradeTemplate: (payload) => ({type: actionTypes.FETCH_BIDTRADETEMPLATE, payload: payload}),
  putBidTradeTemplate: (payload) => ({type: actionTypes.PUT_BIDTRADETEMPLATE, payload: payload}),
  deleteBidTradeTemplate: (payload) => ({type: actionTypes.DELETE_BIDTRADETEMPLATE, payload: payload}),
  putBidTradeItemTemplate: (payload) => ({type: actionTypes.PUT_BIDTRADEITEMTEMPLATE, payload: payload}),
  deleteBidTradeItemTemplate: (payload) => ({type: actionTypes.DELETE_BIDTRADEITEMTEMPLATE, payload: payload}),
  fetchClarificationTemplates: () => ({type: actionTypes.FETCH_CLARIFICATIONTEMPLATES}),
  fetchClarificationTemplate: (payload) => ({type: actionTypes.FETCH_CLARIFICATIONTEMPLATE, payload: payload}),
  putClarificationTemplate: (payload) => ({type: actionTypes.PUT_CLARIFICATIONTEMPLATE, payload: payload}),
  putClarification: (payload) => ({type: actionTypes.PUT_Clarification, payload: payload}),
  deleteClarificationTemplate: (payload) => ({type: actionTypes.DELETE_CLARIFICATIONTEMPLATE, payload: payload}),

  fetchBidQuoteChecklistTemplates: (payload) => ({type: actionTypes.FETCH_BidQuoteChecklistTemplates, payload: payload}),
  fetchBidQuoteChecklistTemplate: (payload) => ({type: actionTypes.FETCH_BidQuoteChecklistTemplate, payload: payload}),
  setBidQuoteChecklistTemplateItems: (payload) => ({type: actionTypes.SET_BidQuoteChecklistTemplateItems, payload: payload}),
  putBidQuoteChecklistTemplate: (payload) => ({type: actionTypes.PUT_BidQuoteChecklistTemplate, payload: payload}),
  deleteBidQuoteChecklistTemplate: (payload) => ({type: actionTypes.DELETE_BidQuoteChecklistTemplate, payload: payload}),
  putBidQuoteChecklistItemTemplate: (payload) => ({type: actionTypes.PUT_BidQuoteChecklistItemTemplate, payload: payload}),
  deleteBidQuoteChecklistItemTemplate: (payload) => ({type: actionTypes.DELETE_BidQuoteChecklistItemTemplate, payload: payload})
}

function* actionWatcher() {
  yield takeLatest('SET_VALIDUSER', fetchReferences)
  yield takeLatest('FETCH_EXPENSETEMPLATES', fetchExpenseTemplates)
  yield takeLatest('FETCH_EXPENSETEMPLATEITEMS', fetchExpenseTemplateItems)
  yield takeLatest('FETCH_DISPLAY_OPTIONS', getDisplayOptions)
  yield takeLatest('FETCH_Tags', fetchTags)
  yield takeLatest('FETCH_PLUGINS', fetchPlugins)

  yield takeLatest('FETCH_CSIDIVISIONS', fetchCSIDivisions)
  yield takeLatest('FETCH_CSICodes', fetchCSICodes)
  yield takeLatest('FETCH_HelpTopics', fetchHelpTopics)
  yield takeLatest('FETCH_HelpVideos', fetchHelpVideos)
  yield takeLatest('DELETE_HelpVideoFile', deleteHelpVideoFile)
  yield takeLatest('FETCH_EXPENSECATEGORIES', fetchExpenseCategories) //
  yield takeLatest('FETCH_ExpenseUnits', fetchExpenseUnits) //
  yield takeLatest('FETCH_Employees', fetchEmployees) //
  yield takeLatest('FETCH_EmployeeRoles', fetchEmployeeRoles) //
  yield takeLatest('FETCH_States', fetchStates) //
  yield takeLatest('FETCH_Counties', fetchCounties) //
  yield takeLatest('FETCH_BuildersRiskRates', fetchBuildersRiskRates) //
  yield takeLatest('FETCH_BusinessUnits', fetchBusinessUnits) //
  yield takeLatest('FETCH_SystemParams', fetchSystemParams) //
  yield takeLatest('FETCH_Tasks', fetchTasks)
  yield takeLatest('FETCH_Users', fetchUsers)
  yield takeLatest('FETCH_UserRoles', fetchUserRoles) //
  yield takeLatest('FETCH_Contexts', fetchContexts) //
  yield takeLatest('FETCH_PERDIEMRATES', fetchPerDiemRates)
  yield takeLatest('FETCH_APPROVERS', fetchApprovers)

  yield takeLatest('FETCH_SEARCHCRM', fetchSearchCRM)
  yield takeLatest('PUT_SEARCHCRM', postSearchCRM)
  yield takeLatest('PUT_SYNCBCPROJECT', postSyncBCProject)

  yield takeLatest('CHANGE_EXPENSE_CATEGORY_GRID_ROW', putExpenseCategoryGridRow)
  yield takeLatest('DELETE_EXPENSE_CATEGORY_GRID_ROW', putDeleteExpenseCategoryGridRow)
  yield takeLatest('CHANGE_EXPENSE_ITEM_GRID_ROW', putExpenseItemGridRow)
  yield takeLatest('CHANGE_EXPENSE_UNIT_ROW', putExpenseUnitRow)
  yield takeLatest('CHANGE_OFFICE_GRID_ROW', putOfficeGridRow)
  yield takeLatest('CHANGE_STATE_GRID_ROW', putStateGridRow)
  yield takeLatest('CHANGE_COUNTY_GRID_ROW', putCountyGridRow)
  yield takeLatest('CHANGE_BUILDERSRISKRATE_GRID_ROW', putBuildersRiskRateGridRow)
  yield takeLatest('CHANGE_EMPLOYEE_ROLE_GRID_ROW', putEmployeeRoleGridRow)
  yield takeLatest('CHANGE_EMPLOYEE_GRID_ROW', putEmployeeGridRow)
  yield takeLatest('CHANGE_TASK_GRID_ROW', putTaskGridRow)
  yield takeLatest('CHANGE_BUSINESSUNIT_ROW', putBusinessUnitRow)
  yield takeLatest('PUT_HELP_TOPIC', putHelpTopic)
  yield takeLatest('PUT_HELP_VIDEO', putHelpVideo)
  yield takeLatest('PUT_PERDIEMRATE', putPerDiemRate)
  yield takeLatest('DELETE_PERDIEMRATE', deletePerDiemRate)

  yield takeLatest('ADD_BID_PERMISSION', putNewBidPermission)
  yield takeLatest('DELETE_BID_PERMISSION', putDeleteBidPermission)
  yield takeLatest('DELETE_EXPENSETEMPLATE', putDeleteExpenseTemplate)
  yield takeLatest('DELETE_EXPENSE_UNIT_ROW', putDeleteExpenseUnitRow)
  yield takeLatest('DELETE_EXPENSETEMPLATEITEM', putDeleteExpenseTemplateItem)
  yield takeLatest('DELETE_HelpTopic', deleteHelpTopic)

  yield takeLatest('PUT_USER', putUser)
  yield takeLatest('DELETE_USER', deleteUser)
  yield takeLatest('PUT_DISPLAY_OPTIONS', putDisplayOptions)
  yield takeLatest('PUT_SYSTEMPARAMETER', putSystemParameter)
  yield takeLatest('PUT_EXPENSETEMPLATE', putExpenseTemplate)

  yield takeLatest('PUT_CSICODE', putCSICode)
  yield takeLatest('PUT_BUSINESSUNIT_CSICODE', putBusinessUnitCsiCode)
  yield takeLatest('PUT_Clarification', putClarification)
  yield takeLatest('DELETE_Clarification', deleteClarification)

  yield takeLatest('FETCH_BIDTRADETEMPLATE', fetchBidTradeTemplate)
  yield takeLatest('FETCH_BIDTRADETEMPLATES', fetchBidTradeTemplates)
  yield takeLatest('PUT_BIDTRADETEMPLATE', putBidTradeTemplate)
  yield takeLatest('DELETE_BIDTRADETEMPLATE', deleteBidTradeTemplate)
  yield takeEvery('PUT_BIDTRADEITEMTEMPLATE', putBidTradeItemTemplate)
  yield takeEvery('DELETE_BIDTRADEITEMTEMPLATE', deleteBidTradeItemTemplate)

  yield takeLatest('FETCH_CLARIFICATIONTEMPLATES', fetchClarificationTemplates)
  yield takeLatest('FETCH_CLARIFICATIONTEMPLATE', fetchClarificationTemplate)
  yield takeLatest('PUT_CLARIFICATIONTEMPLATE', putClarificationTemplate)
  yield takeLatest('DELETE_CLARIFICATIONTEMPLATE', deleteClarificationTemplate)

  yield takeLatest('FETCH_BidQuoteChecklistTemplates', fetchBidQuoteChecklistTemplates)
  yield takeLatest('FETCH_BidQuoteChecklistTemplate', fetchBidQuoteChecklistTemplate)
  yield takeLatest('PUT_BidQuoteChecklistTemplate', putBidQuoteChecklistTemplate)
  yield takeLatest('DELETE_BidQuoteChecklistTemplate', deleteBidQuoteChecklistTemplate)
  yield takeLatest('PUT_BidQuoteChecklistItemTemplate', putBidQuoteChecklistItemTemplate)
  yield takeLatest('DELETE_BidQuoteChecklistItemTemplate', deleteBidQuoteChecklistItemTemplate)

  yield takeLatest('PUT_TAG', putTag)
  yield takeLatest('PUT_ERROR', putError)
}

export function* saga() {
  yield all([actionWatcher()])
}
