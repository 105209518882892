export const pageMeta = {
  dashboard: {
    title: 'Dashboard',
    header: 'Dashboard',
    helpModule: 'Dashboard'
  },
  setup: {
    title: 'Project Setup',
    header: 'Project Setup',
    helpModule: 'Project Setup'
  },
  summary: {
    title: 'Pricing Review Summary',
    header: 'Pricing Review Summary',
    helpModule: 'Summary'
  },
  'summary#laborsummary': {
    helpModule: 'Labor Summary',
    isSubModule: true
  },
  /* GCs and Fee */
  precondept: {
    title: 'Preconstruction',
    header: 'Preconstruction',
    helpModule: 'Preconstruction'
  },
  projectteam: {
    title: 'Project Team',
    header: 'Project Team',
    helpModule: 'Project Team'
  },
  fieldofficesupport: {
    title: 'Field Office Support',
    header: 'Field Office Support',
    helpModule: 'Field Office Support'
  },
  generalrequirements: {
    title: 'General Requirements',
    header: 'General Requirements',
    helpModule: 'General Requirements'
  },
  client: {
    title: 'Client Summary',
    header: 'Client Summary',
    helpModule: 'Client Summary'
  },
  targetefficiency: {
    title: 'Target Efficiency',
    header: 'Target Efficiency',
    helpModule: 'Target Efficiency'
  },
  nonbillable: {
    title: 'Non Billable Summary',
    header: 'Non Billable Summary',
    helpModule: 'Non Billable'
  },
  /* Trade Pricing */
  searchnsynchcrm: {
    title: 'Search and Sync CRM',
    header: 'Search and Sync CRM'
  },
  // setuptradepricing: {
  //   title: 'Bid Setup',
  //   header: 'Bid Setup'
  // },
  tradepricingsum: {
    title: 'Executive Summary',
    header: 'Executive Summary',
    helpModule: 'Executive Summary'
  },
  bidsheet: {
    title: 'Trade Summary',
    header: 'Trade Summary',
    helpModule: 'Trade Summary'
  },
  generalscope: {
    title: 'General Scope',
    header: 'General Scope',
    helpModule: 'General Scope'
  },
  trades: {
    title: 'Trade Scope',
    header: 'Trade Scope',
    helpModule: 'Trade Scope'
  },
  'trades#items': {
    helpModule: 'Scope Items',
    isSubModule: true
  },
  'trades#itemsBudget': {
    helpModule: 'Internal Estimate',
    isSubModule: true
  },
  'trades#files': {
    helpModule: 'Files',
    isSubModule: true
  },
  'trades#unitprices': {
    helpModule: 'Trade Scope Unit Prices',
    isSubModule: true
  },
  bidclarifications: {
    title: 'Clarifications',
    header: 'Clarifications',
    helpModule: 'Clarifications'
  },
  alternates: {
    title: 'Alternates',
    header: 'Alternates',
    helpModule: 'Alternates'
  },
  ve: {
    title: 'Value Engineering',
    header: 'Value Engineering',
    helpModule: 'Value Engineering'
  },
  unitprices: {
    title: 'Unit Prices',
    header: 'Unit Prices',
    helpModule: 'Unit Prices'
  },
  bidallowances: {
    title: 'Allowances',
    header: 'Allowances',
    helpModule: 'Allowances'
  },
  // proposals: {
  //   title: 'Proposals',
  //   header: 'Proposals'
  // },
  /* Templates */
  bidtradetemplates: {
    title: 'Bid Trade Templates - System Configuration',
    header: 'System Configuration'
  },
  bidquotechecklists: {
    title: 'Bid Quote Checklists - System Configuration',
    header: 'System Configuration'
  },
  clarifications: {
    title: 'Clarifications - System Configuration',
    header: 'System Configuration'
  },
  /* System Administration */
  // userroles: {
  //   title: 'User Roles - System Configuration',
  //   header: 'System Configuration',
  // },
  users: {
    title: 'User Permissions - System Configuration',
    header: 'System Configuration'
  },
  'systemplates/expensetemplates': {
    title: 'Expense Templates - System Configuration',
    header: 'System Configuration'
  },
  'systemplates/expenseunits': {
    title: 'Expense Units - System Configuration',
    header: 'System Configuration'
  },
  'systemplates/metadataconfig': {
    title: 'Meta Data Config - System Configuration',
    header: 'System Configuration'
  },
  'trades/subcontractors': {
    title: 'Subcontractors - System Configuration',
    header: 'System Configuration'
  },
  'trades/addsubcontractors': {
    title: 'Subcontractors - System Configuration',
    header: 'Subcontractors - System Configuration'
  },
  'trades/csicodes': {
    title: 'CSI Codes - System Configuration',
    header: 'System Configuration'
  },
  'staff/employees': {
    title: 'Employees - System Configuration',
    header: 'System Configuration'
  },
  'staff/employeeroles': {
    title: 'Employee Roles - System Configuration',
    header: 'System Configuration'
  },
  'locations/states': {
    title: 'States - System Configuration',
    header: 'System Configuration'
  },
  'locations/counties': {
    title: 'Counties - System Configuration',
    header: 'System Configuration'
  },
  'locations/buildersriskrates': {
    title: 'Builders Risk Rates - System Configuration',
    header: 'System Configuration'
  },
  'other/systemparams': {
    title: 'System Parameters - System Configuration',
    header: 'System Configuration'
  },
  'other/businessunits': {
    title: 'Business Units - System Configuration',
    header: 'System Configuration'
  },
  'other/tasks': {
    title: 'Tasks - System Configuration',
    header: 'System Configuration'
  },
  'other/perdiemrates': {
    title: 'Per Diem Rates - System Configuration',
    header: 'System Configuration'
  },
  'other/stagingdatatools': {
    title: 'Staging Data Tools - System Configuration',
    header: 'System Configuration'
  },
  'help/helptopics': {
    title: 'Help Topics - System Configuration',
    header: 'System Configuration'
  },
  'help/helpvideos': {
    title: 'Help Videos - System Configuration',
    header: 'System Configuration'
  },
  HelpVideosLibrary: {
    title: 'Training Videos',
    header: 'Training Videos'
  }
  // expensecategories: {
  //   title: 'Expense Categories - System Configuration',
  //   header: 'System Configuration'
  // },
  // expenseitems: {
  //   title: 'Expense Items - System Configuration',
  //   header: 'System Configuration'
  // },
  // offices: {
  //   title: 'Offices - System Configuration',
  //   header: 'System Configuration'
  // },
  // csiversions: {
  //   title: 'CSI Versions - System Configuration',
  //   header: 'System Configuration'
  // },
}

export const getPageHeader = (path) => {
  if (pageMeta[path] && pageMeta[path].header) {
    return pageMeta[path].header
  }
  return 'HPM'
}

export const getPageTitle = (path) => {
  if (pageMeta[path] && pageMeta[path].title) {
    return `${pageMeta[path].title} - HITT Pricing Model 2.0`
  }
  return 'HITT Pricing Model 2.0'
}

export const helpModules = Object.values(pageMeta)
  .filter((page) => page.helpModule)
  .map((page) => ({
    helpModule: page.helpModule,
    isSubModule: page.isSubModule
  }))

export const getHelpModulesForPage = (path, hash) => {
  const helpModule = pageMeta[path] && pageMeta[path].helpModule
  const helpModules = helpModule ? [helpModule] : []
  if (hash) {
    const hashHelpModule = pageMeta[`${path}#${hash}`] && pageMeta[`${path}#${hash}`].helpModule
    if (hashHelpModule) {
      helpModules.push(hashHelpModule)
    }
  }
  return helpModules
}
