import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {Formik} from 'formik'
import * as Yup from 'yup'
import {DataGridPro} from '@mui/x-data-grid-pro/DataGridPro'
import * as Precon from '../../../store/ducks/precon.duck'

export default function SubContractorOffice(props) {
  const dispatch = useDispatch()
  const offices = useSelector((state) => state.precon.SubcontractorOffices)

  useEffect(() => {
    dispatch(Precon.actions.fetchSubcontractorOffices({subcontractorID: props.subcontractorID}))
  }, [dispatch, props.subcontractorID])

  var [isHeadOffice, setIsHeadOffice] = useState(false)

  const handleIsHeadOfficeChange = (event) => {
    setIsHeadOffice(event.target.value)
  }

  const initSubcontractorOffices = () => {
    return {
      id: 0,
      subcontractorOfficeID: 0,
      subContractorID: 0,
      name: '',
      address: '',
      city: '',
      stateCode: '',
      zip: '',
      country: ''
    }
  }
  const [office, setOffice] = useState(initSubcontractorOffices())

  function loadOffice(thisOffice) {
    setOffice(thisOffice)
    setIsHeadOffice(thisOffice.isheadOffice)
  }

  function newOffice() {
    setOffice(initSubcontractorOffices())
    setIsHeadOffice('false')
  }

  const officesCols = [
    {field: 'id', headerName: 'id', hide: true},
    {
      field: 'name',
      type: 'string',
      headerName: 'Office Name',
      width: 280,
      renderCell: (params) => (
        <strong>
          <span onClick={() => loadOffice(offices.find((row) => row.id === params.row.id))} className='cell-link'>
            {params.row.address === ' ' ? params.row.city : params.row.address}
          </span>{' '}
          <span className='font-normal'>{params.row.isheadOffice && ' (Head Office)'}</span>
        </strong>
      )
    },
    {field: 'city', type: 'string', headerName: 'City', width: 100},
    {field: 'stateCode', type: 'string', headerName: 'State', flex: 1}
  ]

  const submitForm = (values) => {
    // Handle select used for head office determination
    values.isheadOffice = isHeadOffice
    values.subContractorID = props.subcontractorID
    console.log(values)
    dispatch(
      Precon.actions.putSubcontractorOffices({
        values: values,
        subcontractorID: props.subcontractorID
      })
    )
  }

  const validationSchema = Yup.object().shape({
    address: Yup.string().required('Address is required')
  })

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <h5>Offices List</h5>
          <div className='bg-white-primary h-[90%] mt-[10px]'>
            <DataGridPro
              rows={offices}
              columns={officesCols}
              checkboxSelection={false}
              sortModel={[{field: 'name', sort: 'asc'}]}
              rowHeight={38}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false
                  }
                }
              }}
            />
          </div>
        </Grid>
        <Grid item md={6} className='pl-[10px]'>
          <h5>Office Details</h5>
          <Formik
            enableReinitialize={true}
            initialValues={office}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              submitForm(values)
              // actions.setSubmitting(false);
            }}
          >
            {(props) => {
              const {values, errors, touched, handleChange, handleSubmit} = props
              const change = (name, e) => {
                e.persist()
                handleChange(e)
              }
              return (
                <form noValidate={true} autoComplete='off' onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          type='text'
                          id='address'
                          name='address'
                          value={values.address}
                          onChange={change.bind(null, 'address')}
                          autoComplete='new-password'
                          isInvalid={touched.address && !!errors.address}
                          isValid={touched.address && !errors.address}
                        />
                        <Form.Control.Feedback type='invalid'>{errors.address}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type='text'
                          id='city'
                          name='city'
                          value={values.city}
                          onChange={change.bind(null, 'city')}
                          autoComplete='new-password'
                          isInvalid={touched.city && !!errors.city}
                          isValid={touched.city && !errors.city}
                        />
                        <Form.Control.Feedback type='invalid'>{errors.city}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mt-8'>
                    <Col md='6'>
                      <Form.Group>
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          type='text'
                          id='stateCode'
                          name='stateCode'
                          value={values.stateCode}
                          onChange={change.bind(null, 'stateCode')}
                          autoComplete='new-password'
                          isInvalid={touched.stateCode && !!errors.stateCode}
                          isValid={touched.stateCode && !errors.stateCode}
                        />
                        <Form.Control.Feedback type='invalid'>{errors.stateCode}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md='3'>
                      <Form.Group>
                        <Form.Label>ZIP</Form.Label>
                        <Form.Control
                          type='text'
                          id='zip'
                          name='zip'
                          value={values.zip}
                          onChange={change.bind(null, 'zip')}
                          autoComplete='new-password'
                          isInvalid={touched.zip && !!errors.zip}
                          isValid={touched.zip && !errors.zip}
                        />
                        <Form.Control.Feedback type='invalid'>{errors.zip}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mt-8'>
                    <Col>
                      <Form.Group>
                        <FormControl variant='outlined' size='small'>
                          <Form.Label>Head Office</Form.Label>
                          <Select
                            id='isheadofficeSelector'
                            aria-label='Is Head Office Selector'
                            name='isheadOffice'
                            value={isHeadOffice}
                            onChange={handleIsHeadOfficeChange}
                            className='bg-white-primary'
                          >
                            <MenuItem key='True' value='true'>
                              Yes
                            </MenuItem>
                            <MenuItem key='False' value='false'>
                              No
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row className='mt-8'>
                    <Grid container direction='row' justifyContent='space-between'>
                      <Grid item md={6}></Grid>
                      <Grid item>
                        <Button variant='secondary' onClick={() => newOffice()}>
                          Clear / New
                        </Button>
                        <Button variant='primary' className='ml-2 mr-[10px]' type='submit'>
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Row>
                </form>
              )
            }}
          </Formik>
        </Grid>
      </Grid>
    </>
  )
}
