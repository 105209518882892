/* eslint-disable react/jsx-no-target-blank */
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemLogout} from './SidebarMenuItemLogout'
import {useSelector} from 'react-redux'
// import APIMessages from '../../../../../app/components/APIMessages';

const SidebarMenuMain = () => {
  const userRole = useSelector((state: any) => state.auth.roleDesc)

  const BaseItems = () => {
    return (
      <>
        <SidebarMenuItem to='/dashboard' title='Dashboard' fontIcon='bi-bar-chart' />
        <SidebarMenuItem to='/setup' title='Project Setup' fontIcon='bi-gear' />
        <SidebarMenuItem to='/summary' title='Pricing Review Summary' fontIcon='bi-file-ruled' />
      </>
    )
  }

  const GCsAndFeeItems = () => {
    return (
      <>
        <SidebarMenuItem to='/precondept' title='Preconstruction' fontIcon='bi-collection' />
        <SidebarMenuItem to='/projectteam' title='Project Team' fontIcon='bi-calendar2-date' />
        <SidebarMenuItem to='/fieldofficesupport' title='Field Office Support' fontIcon='bi-house-gear' />
        <SidebarMenuItem to='/generalrequirements' title='General Requirements' fontIcon='bi-cone-striped' />
        <SidebarMenuItem to='/client' title='Client Summary' fontIcon='bi-calendar-check' />
        <SidebarMenuItem to='/targetefficiency' title='Target Efficiency' fontIcon='bi-graph-up-arrow' />
        <SidebarMenuItem to='/nonbillable' title='Non Billable' fontIcon='bi-receipt' />
      </>
    )
  }

  const TradePricingItems = () => {
    return (
      <>
        <SidebarMenuItem to='/tradepricingsum' title='Executive Summary' fontIcon='bi-calculator' />
        <SidebarMenuItem to='/bidsheet' title='Trade Summary' fontIcon='bi-clipboard2-check' />
        <SidebarMenuItem to='/generalscope' title='General Scope' fontIcon='bi-list-check' />
        <SidebarMenuItem to='/trades' title='Trade Scope' fontIcon='bi-pencil-square' />
        <SidebarMenuItem to='/bidclarifications' title='Clarifications' fontIcon='bi-info-circle' />
        <SidebarMenuItem to='/alternates' title='Alternates' fontIcon='bi-stickies' />
        <SidebarMenuItem to='/ve' title='Value Engineering' fontIcon='bi-piggy-bank' />
        <SidebarMenuItem to='/unitprices' title='Unit Prices' fontIcon='bi-tag' />
        <SidebarMenuItem to='/BidAllowances' title='Allowances' fontIcon='bi-bookmark-plus' />
      </>
    )
  }

  const AdminItems = () => {
    return (
      <>
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>System Administration</span>
          </div>
        </div>

        <SidebarMenuItem to='/Users' title='User Permissions' fontIcon='bi-person-lock' />

        <SidebarMenuItemWithSub to='/SysTemplates' title='Templates' fontIcon='bi-folder-plus'>
          <SidebarMenuItem to='/SysTemplates/ExpenseTemplates' title='Expense Templates' hasBullet={true} />
          <SidebarMenuItem to='/SysTemplates/ExpenseUnits' title='Expense Units' hasBullet={true} />
          <SidebarMenuItem to='/SysTemplates/MetaDataConfig' title='Meta Data Config' hasBullet={true} />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/Trades' title='Trades' fontIcon='bi-journals'>
          <SidebarMenuItem to='/Trades/Subcontractors' title='Subcontractors' hasBullet={true} />
          <SidebarMenuItem to='/Trades/CSICodes' title='CSI Codes' hasBullet={true} />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/Staff' title='Employees' fontIcon='bi-people'>
          <SidebarMenuItem to='/Staff/Employees' title='Employees' hasBullet={true} />
          <SidebarMenuItem to='/Staff/EmployeeRoles' title='Employee Roles' hasBullet={true} />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/Locations' title='Locations' fontIcon='bi-geo-alt'>
          <SidebarMenuItem to='/Locations/States' title='States' hasBullet={true} />
          <SidebarMenuItem to='/Locations/Counties' title='Counties' hasBullet={true} />
          <SidebarMenuItem to='/Locations/BuildersRiskRates' title='Builders Risk Rates' hasBullet={true} />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/Other/' title='Other' fontIcon='bi-gear'>
          <SidebarMenuItem to='/Other/SystemParams' title='System Parameters' hasBullet={true} />
          <SidebarMenuItem to='/Other/BusinessUnits' title='Business Units' hasBullet={true} />
          <SidebarMenuItem to='/Other/Tasks' title='Precon Tasks' hasBullet={true} />
          <SidebarMenuItem to='/Other/perdiemrates' title='Per Diem Rates' hasBullet={true} />
          <SidebarMenuItem to='/Other/StagingDataTools' title='Staging Data Tools' hasBullet={true} />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/Help/' title='Help' fontIcon='bi-question-circle'>
          <SidebarMenuItem to='/Help/HelpTopics' title='Help Topics' hasBullet={true} />
          <SidebarMenuItem to='/Help/HelpVideos' title='Help Videos' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </>
    )
  }

  return (
    <>
      {/* <APIMessages /> */}
      <BaseItems />
      <SidebarMenuItemWithSub to='/gcfmenu' title='GCs and Fee' fontIcon='bi-chevron-right'>
        <GCsAndFeeItems />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/tpmenu' title='Trade Pricing' fontIcon='bi-chevron-right'>
        <TradePricingItems />
      </SidebarMenuItemWithSub>
      {/* <SidebarMenuItem to='/proposals' title="Proposals" fontIcon='bi-person-check' /> */}
      <SidebarMenuItemWithSub to='/templates' title='Templates' fontIcon='bi-folder-plus'>
        <SidebarMenuItem to='/BidTradeTemplates' title='Trade Scope Templates' hasBullet={true} />
        <SidebarMenuItem to='/bidquotechecklists' title='General Scope Templates' hasBullet={true} />
        <SidebarMenuItem to='/Clarifications' title='Clarifications' hasBullet={true} />
      </SidebarMenuItemWithSub>

      {/* <SidebarMenuItem to='/snapshots' title='Bid Snapshots' fontIcon='bi-file-earmark-lock' /> */}
      <SidebarMenuItem to='/HelpVideosLibrary' title='Training Videos' fontIcon='bi-play-btn' />

      {/* Log out */}
      <SidebarMenuItemLogout />

      {userRole === 'Administrator' ? <AdminItems /> : null}
    </>
  )
}

export {SidebarMenuMain}
