import {useEffect, useCallback, useMemo} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Project from '../store/ducks/project.duck'
import * as Auth from '../store/ducks/auth.duck'
import Export from './Export'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {Portlet, PortletBody} from '../partials/content/Portlet'
import CloseIcon from '@mui/icons-material/Close'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import format from 'date-fns/format'
import {useAppModal} from '../context/GlobalModalContext'

export const DrawMessage = ({text}) => {
  return <span className='project-header-status-message'>{text}</span>
}

export const DrawBCMessage = ({text}) => {
  return <span className='project-header-status-message'>{text}</span>
}

export const ClientExportBidHeader = ({snapshot, snapshotHeader, handleOnClickClose}) => {
  return (
    <div className='project-header-client-export bid-header'>
      <Grid container spacing={0} justifyContent='flex-end' alignItems='flex-end'>
        <Grid className='mt-[5px]' item xs={12} md={12}>
          <Grid container justifyContent='flex-end'>
            <Export
              payload={{
                projectID: snapshot.projectID,
                bidQuoteID: snapshot.bidQuoteID,
                snapshotID: snapshot.id,
                fileName: `Pricing`,
                exportName: 'BidTradeSummary',
                type: 'excel'
              }}
            />
            <CloseIcon className='cursor-pointer mt-[5px] ml-[10px]' onClick={handleOnClickClose} />
          </Grid>
        </Grid>
        <Grid className='mt-[35px]' item xs={6} md={6}>
          <Typography align='left' className='project-header-client-md'>
            {snapshotHeader.name} {process.env.REACT_APP_ENV !== 'production' ? `(${process.env.REACT_APP_ENV})` : ''}
          </Typography>
          <Typography align='left' className='project-header-client-sm'>
            {snapshotHeader.street}
            {snapshotHeader.street && snapshotHeader.street.length > 1 ? ',' : ''} {snapshotHeader.city}
          </Typography>
          <Typography align='left' className='project-header-client-sm'>
            {snapshotHeader.state}
            {snapshotHeader.zipCode}
          </Typography>
        </Grid>
        <Grid className='mt-20' item xs={6} md={6}>
          <Typography align='right' className='project-header-client-xs'>
            <span className='text-purple-light'>Version:</span> {snapshot && snapshot.versionNo}
          </Typography>
          <Typography align='right' className='project-header-client-xs'>
            <span className='text-purple-light'>Created:</span> {snapshot && snapshot.snapshotTS ? format(new Date(snapshot.snapshotTS), 'MM-dd-yyyy') : ''}
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export const DefaultBidHeader = ({className, header, fullscreen, apiMessages, projectID, editPermission, addCopyDescription, handleExitFullScreen, handleEnterFullScreen}) => {
  return (
    <Portlet className={className}>
      <PortletBody fit={true} className='project-header-grid-container'>
        <Grid container spacing={0}>
          <Grid item md={8}>
            <Typography align='left' className={`project-header-value-lg${fullscreen ? '-fullscreen' : ''}`}>
              {header.name === '' ? 'No Project Loaded' : header.name} {addCopyDescription()}
              <span className='text-silver-primary'>{projectID && !editPermission ? '(Read Only)' : null}</span>
            </Typography>
          </Grid>
          <Grid item md={4} className='project-header-status'>
            <DrawMessage text={apiMessages} /> <ArrowBackIosIcon className='text-[14px]' />
            {fullscreen ? (
              <FullscreenExitIcon onClick={handleExitFullScreen} className='fullscreen-icon' />
            ) : (
              <FullscreenIcon onClick={handleEnterFullScreen} className='fullscreen-icon' alt='Fullscreen' />
            )}
          </Grid>
          {!fullscreen ? (
            <>
              <Grid item md={6} className='project-header-left'>
                <Grid container spacing={0}>
                  <Grid item xs={7} md={6}>
                    <Typography align='left' className='project-header-label-sm'>
                      Address:
                    </Typography>
                    <Typography align='left' className='project-header-value-sm'>
                      {header.street}
                      {header.street && header.street.length > 1 ? ',' : ''} {header.city} {header.stateAbbr}
                    </Typography>
                  </Grid>
                  <Grid item xs={5} md={6}>
                    <Typography align='left' className='project-header-label-sm'>
                      Opportunity No / Business Unit:
                    </Typography>
                    <Typography align='left' className='project-header-value-sm'>
                      {header.CRM_OpportunityNo} / {header.CRM_PrimaryBusinessUnit}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6} className='project-header-right'>
                <Grid container spacing={0}>
                  <Grid item xs={4} md={4}>
                    <Typography align='left' className='project-header-label-sm'>
                      Architect / Engineer:
                    </Typography>
                    <Typography align='left' className='project-header-value-sm'>
                      {header.CRM_LeadDesignFirm}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Typography align='left' className='project-header-label-sm'>
                      Contract Type:
                    </Typography>
                    <Typography align='left' className='project-header-value-sm'>
                      {header.CRM_ContractType}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Typography align='left' className='project-header-label-sm'>
                      Project / Work Type:
                    </Typography>
                    <Typography align='left' className='project-header-value-sm'>
                      {header.projectType !== '' ? header.projectType : '-'} / {header.workType !== '' ? header.workType : '-'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : null}
        </Grid>
      </PortletBody>
    </Portlet>
  )
}

function BidHeaderContainer({type}) {
  const dispatch = useDispatch()
  const projectID = useSelector((state) => state.project.projectID)
  const header = useSelector((state) => state.project.projectHeader.values)
  const editPermission = useSelector((state) => state.project.editPermission)
  const apiMessages = useSelector((state) => state.project.apiMessages)
  const snapshot = useSelector((state) => state.precon.Snapshot)
  const fullscreen = useSelector((state) => state.auth.Filters.fullscreen)

  const {closeModal} = useAppModal()

  function addHeaderFlag() {
    let headerFlag = ''
    if (header.isCopy) {
      headerFlag = headerFlag + header.copyDescription
    }
    if (process.env.REACT_APP_ENV !== 'production') {
      headerFlag = headerFlag + ` - ${process.env.REACT_APP_ENV.toUpperCase()}`
    }
    return <span className='text-red-primary'> {headerFlag}</span>
  }

  const handleEnterFullScreen = useCallback(() => {
    dispatch(Auth.actions.setFilters({filterName: 'fullscreen', filterValue: true}))
  }, [dispatch])

  const handleExitFullScreen = useCallback(() => {
    dispatch(Auth.actions.setFilters({filterName: 'fullscreen', filterValue: false}))
  }, [dispatch])

  const snapshotHeader = useMemo(() => {
    const initHeader = {
      name: '',
      street: '',
      city: '',
      zipCode: '',
      opportunityNo: '',
      primaryBusinessUnit: '',
      leadDesignFirm: '',
      contractType: '',
      projectType: '',
      workType: ''
    }
    return snapshot && snapshot.nodes && snapshot.nodes.some((node) => node.nodeName === 'ProjectHeader')
      ? JSON.parse(snapshot.nodes.find((node) => node.nodeName === 'ProjectHeader').payload)[0]
      : initHeader
  }, [snapshot])

  useEffect(() => {
    dispatch(Project.actions.fetchProjectHeader({projectID: projectID}))
  }, [dispatch, projectID])

  const className = useMemo(() => `bid-header ${fullscreen ? 'mb-[5px]' : 'mb-[20px]'}`, [fullscreen])

  if (!header) {
    return null
  }

  if (type === 'clientExport') {
    return (
      // <Portlet >
      <ClientExportBidHeader snapshot={snapshot} snapshotHeader={snapshotHeader} handleOnClickClose={closeModal} />
      // </Portlet>
    )
  } else {
    return (
      <DefaultBidHeader
        className={className}
        header={header}
        fullscreen={fullscreen}
        apiMessages={apiMessages}
        projectID={projectID}
        editPermission={editPermission}
        addCopyDescription={addHeaderFlag}
        handleExitFullScreen={handleExitFullScreen}
        handleEnterFullScreen={handleEnterFullScreen}
      />
    )
  }
}

export default BidHeaderContainer
